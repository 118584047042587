import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './navbarcomponent.css';
import logo from './image-4.webp';
import AnimatedBurger from './AnimatedBurger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isPricingOpen, setIsPricingOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setIsServicesOpen(false);
      setIsPricingOpen(false);
    }
  };

  const closeNavbar = () => {
    setIsOpen(false);
    setIsServicesOpen(false);
    setIsPricingOpen(false);
  };

  const toggleServicesDropdown = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const togglePricingDropdown = () => {
    setIsPricingOpen(!isPricingOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const burgerButtonStyle = {
    display: windowWidth >= 992 ? 'none' : 'block'
  };

  const isServicesActive = ['/mobile-app', '/website-applications', '/graphic-design', '/insurance-page', '/other-mobile-apps'].includes(location.pathname);
  const isPricingActive = location.pathname === '/mobile-app-prices' || location.pathname === '/pricing' || location.pathname === '/divine-messenger' || location.pathname === '/graphic-price' || location.pathname === '/insurance-price';

  const handleContactUsClick = () => {
    navigate('/contact-us');
    closeNavbar();
  };

  return (
    <nav className={`navbar navbar-expand-lg ${scrolled ? 'navbar-scrolled' : 'navbar-top'}`}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/" onClick={closeNavbar}>
          <img src={logo} alt="Divine Software Systems - Web and App Development" height="60" />
        </Link>
        <div style={burgerButtonStyle}>
          <AnimatedBurger isOpen={isOpen} toggle={toggleNavbar} />
        </div>
        <div className={`${isOpen ? 'show' : ''} collapse navbar-collapse`} id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/" onClick={closeNavbar}>Home</Link>
            </li>
            <li className="nav-item dropdown">
              <button
                className={`nav-link dropdown-toggle ${isServicesActive ? 'active' : ''}`}
                id="mobileAppsDropdown"
                type="button"
                aria-expanded={isServicesOpen}
                onClick={toggleServicesDropdown}
              >
                Our Services
              </button>
              <ul className={`dropdown-menu custom-dropdown ${isServicesOpen ? 'show' : ''}`} aria-labelledby="mobileAppsDropdown">
                <li><Link className={`dropdown-item ${location.pathname === '/mobile-app' ? 'active' : ''}`} to="/mobile-app" onClick={closeNavbar}>Mobile Development</Link></li>
                <li><Link className={`dropdown-item ${location.pathname === '/website-applications' ? 'active' : ''}`} to="/website-applications" onClick={closeNavbar}>Web Development</Link></li>
                <li><Link className={`dropdown-item ${location.pathname === '/digital-marketing' ? 'active' : ''}`} to="/digital-marketing" onClick={closeNavbar}>Digital Marketing</Link></li>
                <li><Link className={`dropdown-item ${location.pathname === '/insurance-page' ? 'active' : ''}`} to="/insurance-page" onClick={closeNavbar}>Insurance Website</Link></li>
              </ul>
            </li>
          
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/blog' ? 'active' : ''}`} to="/blog" onClick={closeNavbar}>Blog</Link>
            </li>
            
            <li className="nav-item dropdown">
              <button
                className={`nav-link dropdown-toggle ${isPricingActive ? 'active' : ''}`}
                id="pricesDropdown"
                type="button"
                aria-expanded={isPricingOpen}
                onClick={togglePricingDropdown}
              >
                Pricing
              </button>
              <ul className={`dropdown-menu custom-dropdown ${isPricingOpen ? 'show' : ''}`} aria-labelledby="pricesDropdown">
                <li><Link className={`dropdown-item ${location.pathname === '/mobile-app-prices' ? 'active' : ''}`} to="/mobile-app-prices" onClick={closeNavbar}>Mobile Development</Link></li>
                <li><Link className={`dropdown-item ${location.pathname === '/pricing' ? 'active' : ''}`} to="/pricing" onClick={closeNavbar}>Web Development</Link></li>
                <li><Link className={`dropdown-item ${location.pathname === '//digital-marketing-price' ? 'active' : ''}`} to="/digital-marketing-price" onClick={closeNavbar}>Digital Marketing</Link></li>
                <li><Link className={`dropdown-item ${location.pathname === '/insurance-price' ? 'active' : ''}`} to="/insurance-price" onClick={closeNavbar}>Insurance Website</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/careers' ? 'active' : ''}`} to="/careers" onClick={closeNavbar}>Careers</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/about-us' ? 'active' : ''}`} to="/about-us" onClick={closeNavbar}>About Us</Link>
            </li>
            <li className="nav-item">
              <button className="btn btn-primary contact-nav-btn" onClick={handleContactUsClick}>
                Contact Us <FontAwesomeIcon className="call-now-icon" icon={faArrowCircleRight} />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
