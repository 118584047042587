import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { initGA } from './utils/analytics';
import './App.css';
import Navbar from './navbar/Navbarcomponent';
import HomepageComponent from './homepage/homepagecomponent';
import CardsComponent from './homepage-1/cardcompoenent';
import Wwo from './homepage-2/wwo';
import Footer from './footer-1/footer';
import AboutUs from './about-page/about';
import WebPricing from './web-prices/web';
import Contact from './contact-me/contact';
import MobilePricing from './mobile-prices/mobile';
import WebsiteApp from './website-app/websiteapp';
import Divineprice from './divine-messenger/divine';
import MobileApp from './mobile-app/mobileapp';
import DivineMessengerMobileApp from './divine/divinemessenger';
import PrivacyPolicy from './privacy-policy/privacy';
import TermService from './term-service/term';
import Careers from './view-careers/career';
import CookieConsent from './cookies-check/CookieConsent';
import DigitalMarketing from './graphic-folder/graphic';
import DigitalPrice from './graphic-design/graphic-price';
import InsuranceWebsite from './insurance-page/insurance-hero';
import InsurancePricing from './insurance-folder/insurance-pricing';
import Blog from './blog-folder/blog';
import CaseStudy from './case-study/case1';
import CaseStudy1 from './case-study/case2';
import CaseStudy3 from './case-study/case3';

function App() {
  useEffect(() => {
    initGA();
  }, []);

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, [pathname]);

    return null;
  };

  const getHelmetContent = (path) => {
    switch (path) {
      case '/':
        return {
          title: 'Divine Software Systems | Web Design, Graphic Design, Digital Marketing & App Development',
          description: 'Divine Software Systems delivers top-tier web and app development, graphic design, and digital marketing solutions for business growth.',
          keywords: 'web development, app development, software development, web design, mobile app development, Divine Software Systems, graphic design, digital marketing'
        };
      case '/cards':
        return {
          title: 'Our Services | Divine Software Systems',
          description: 'Explore our web development, app development, and digital marketing services tailored to your business needs.',
          keywords: 'services, web development, app development, Divine Software Systems'
        };
      case '/wwo':
        return {
          title: 'Work With Us | Divine Software Systems',
          description: 'Join Divine Software Systems and contribute to innovative projects that drive business success.',
          keywords: 'work with us, careers, Divine Software Systems'
        };
      case '/about-us':
        return {
          title: 'About Us | Divine Software Systems',
          description: 'Discover our mission and meet the team behind Divine Software Systems\' success in digital innovation.',
          keywords: 'about Divine Software Systems, our mission, our team, software solutions'
        };
      case '/pricing':
        return {
          title: 'Web Design Pricing | Divine Software Systems',
          description: 'Affordable web design pricing tailored for businesses of all sizes at Divine Software Systems.',
          keywords: 'web design pricing, web development pricing, affordable web design, Divine Software Systems'
        };
      case '/contact-us':
        return {
          title: 'Contact Us | Divine Software Systems',
          description: 'Reach out to Divine Software Systems for inquiries, support, or to start your project today.',
          keywords: 'contact Divine Software Systems, support, project inquiry'
        };
      case '/mobile-app-prices':
        return {
          title: 'Mobile App Pricing | Divine Software Systems',
          description: 'Discover competitive pricing for custom mobile app development at Divine Software Systems.',
          keywords: 'mobile app pricing, app development pricing, Divine Software Systems'
        };
      case '/website-applications':
        return {
          title: 'Website Applications | Divine Software Systems',
          description: 'Explore tailored website application development services for your business at Divine Software Systems.',
          keywords: 'website applications, web development, Divine Software Systems'
        };
      case '/divine-messenger':
        return {
          title: 'Divine Messenger | Divine Software Systems',
          description: 'Learn about Divine Messenger, our platform for seamless business communication and collaboration.',
          keywords: 'Divine Messenger, messaging platform, communication, Divine Software Systems'
        };
      case '/mobile-app':
        return {
          title: 'Mobile Applications | Divine Software Systems',
          description: 'Expert mobile app development to create user-friendly and powerful applications at Divine Software Systems.',
          keywords: 'mobile applications, app development, mobile apps, Divine Software Systems'
        };
      case '/other-mobile-apps':
        return {
          title: 'Other Mobile Apps | Divine Software Systems',
          description: 'Browse our diverse range of mobile applications designed for various business needs at Divine Software Systems.',
          keywords: 'mobile apps, diverse applications, Divine Software Systems'
        };
      case '/careers':
        return {
          title: 'Careers | Divine Software Systems',
          description: 'Explore job opportunities and build your career with Divine Software Systems.',
          keywords: 'careers, job opportunities, Divine Software Systems'
        };
        case '/digital-marketing':
          return {
            title: 'Digital Marketing | Divine Software Systems',
            description: 'Comprehensive digital marketing services to boost your online presence and engage your audience at Divine Software Systems.',
            keywords: 'digital marketing, SEO, social media management, email marketing, Divine Software Systems'
          };
        case '/digital-marketing-price':
          return {
            title: 'Digital Marketing Pricing | Divine Software Systems',
            description: 'Affordable pricing for professional digital marketing services at Divine Software Systems.',
            keywords: 'digital marketing pricing, SEO pricing, social media management pricing, Divine Software Systems'
          };
        
      case '/case-study1':
        return {
          title: 'Case Study 1 | Divine Software Systems',
          description: 'Explore our case study demonstrating expertise in web development and digital solutions.',
          keywords: 'case study, web development, digital solutions, Divine Software Systems'
        };
      case '/case-study2':
        return {
          title: 'Case Study 2 | Divine Software Systems',
          description: 'Discover our innovative approach to software development in this detailed case study.',
          keywords: 'case study, software development, Divine Software Systems'
        };
      case '/case-study3':
        return {
          title: 'Case Study 3 | Divine Software Systems',
          description: 'Learn from our successful project highlighting our capabilities in app development.',
          keywords: 'case study, app development, Divine Software Systems'
        };
      case '/insurance-page':
        return {
          title: 'Insurance Website Solutions | Divine Software Systems',
          description: 'Tailored insurance website development solutions to enhance client engagement and streamline insurance operations.',
          keywords: 'insurance websites, insurance web development, custom insurance solutions, Divine Software Systems'
        };
      case '/insurance-price':
        return {
          title: 'Insurance Website Pricing | Divine Software Systems',
          description: 'Affordable pricing for custom insurance website solutions designed to optimize client interactions and business growth.',
          keywords: 'insurance website pricing, custom web development, Divine Software Systems'
        };
      default:
        return {
          title: 'Divine Software Systems',
          description: 'Driving business growth through cutting-edge web and app development, digital innovation, and top-notch software solutions.',
          keywords: 'web development, app development, software development, web design, mobile app development, Divine Software Systems, graphic design, digital marketing'
        };
    }
  };

  const routes = [
    { path: '/', component: <HomepageComponent /> },
    { path: '/cards', component: <CardsComponent /> },
    { path: '/wwo', component: <Wwo /> },
    { path: '/about-us', component: <AboutUs /> },
    { path: '/pricing', component: <WebPricing /> },
    { path: '/contact-us', component: <Contact /> },
    { path: '/mobile-app-prices', component: <MobilePricing /> },
    { path: '/website-applications', component: <WebsiteApp /> },
    { path: '/divine-messenger', component: <Divineprice /> },
    { path: '/mobile-app', component: <MobileApp /> },
    { path: '/other-mobile-apps', component: <DivineMessengerMobileApp /> },
    { path: '/privacy-policy', component: <PrivacyPolicy /> },
    { path: '/terms-of-service', component: <TermService /> },
    { path: '/careers', component: <Careers /> },
    { path: '/digital-marketing', component: <DigitalMarketing /> },
    { path: '/digital-marketing-price', component: <DigitalPrice /> },
    { path: '/case-study1', component: <CaseStudy /> },
    { path: '/case-study2', component: <CaseStudy1 /> },
    { path: '/case-study3', component: <CaseStudy3 /> },
    { path: '/insurance-page', component: <InsuranceWebsite /> },
    { path: '/insurance-price', component: <InsurancePricing /> },
    { path: '/blog', component: <Blog /> },

  ];

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          {routes.map(({ path, component }) => (
            <Route 
              key={path} 
              path={path} 
              element={
                <>
                  <Helmet>
                    <title>{getHelmetContent(path).title}</title>
                    <meta name="description" content={getHelmetContent(path).description} />
                    <meta name="keywords" content={getHelmetContent(path).keywords} />
                    <meta property="og:title" content={getHelmetContent(path).title} />
                    <meta property="og:description" content={getHelmetContent(path).description} />
                    <meta property="og:image" content="https://www.divinesoftwaresystems.com/path_to_your_image.png" />
                    <meta property="og:url" content={`https://www.divinesoftwaresystems.com${path}`} />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={getHelmetContent(path).title} />
                    <meta name="twitter:description" content={getHelmetContent(path).description} />
                    <meta name="twitter:image" content="https://www.divinesoftwaresystems.com/path_to_your_image.png" />
                    <meta name="twitter:url" content={`https://www.divinesoftwaresystems.com${path}`} />
                    <script type="application/ld+json">
                      {`
                        {
                          "@context": "http://schema.org",
                          "@type": "WebSite",
                          "name": "Divine Software Systems",
                          "url": "https://www.divinesoftwaresystems.com",
                          "sameAs": [
                            "https://www.facebook.com/profile.php?id=61558885490773&mibextid=LQQJ4d",
                            "https://www.instagram.com/divinesoftwaresystems?igsh=MzRlODBiNWFlZA==",
                            "https://www.linkedin.com/company/divine-software-systems/",
                            "https://www.yelp.com/biz/divine-software-systems-el-segundo"
                          ],
                          "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://www.divinesoftwaresystems.com/?s={search_term_string}",
                            "query-input": "required name=search_term_string"
                          }
                        }
                      `}
                    </script>
                  </Helmet>
                  {component}
                </>
              }
            />
          ))}
        </Routes>
        <header className="App-header">
          <Navbar />
        </header>
        <Footer />
        <CookieConsent />
      </div>
    </Router>
  );
}

export default App;
