import React, { useState, useEffect, useMemo } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import './mobile.css'; // Ensure this path is correct for your styling
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faLaptopCode, faHeadset, faCogs, faUsers, faShieldAlt, faChartLine, faProjectDiagram, faSyncAlt, faTools, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ContactForm from './ContactForm'; // Import the ContactForm component

const MobilePricing = () => {
  const initialFormData = useMemo(() => ({
    name: '',
    phone: '',
    email: '',
    businessName: '',
    message: '',
    pricingPlanTitle: '', // Added field for the selected pricing plan title
  }), []);

  const [formData, setFormData] = useState(initialFormData);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [selectedPlan, setSelectedPlan] = useState({
    title: '',
    cost: '',
    features: [],
  }); // State to store the selected plan with default values
  const [showSnackbar, setShowSnackbar] = useState(false); // State for showing snackbar

  const handleResize = () => {
    // Removed unused state: setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    const mainContent = document.getElementById('main-content');
    
    if (showModal) {
      document.body.style.overflow = 'hidden';
      if (mainContent) mainContent.classList.add('blur-background');
      if (navbar) navbar.classList.add('blur');
    } else {
      document.body.style.overflow = '';
      if (mainContent) mainContent.classList.remove('blur-background');
      if (navbar) navbar.classList.remove('blur');
      setFormData(initialFormData); // Reset form data when modal is closed
    }
  }, [showModal, initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/mbjnqynw', {
        method: 'POST',
        body: new FormData(e.target),
        headers: {
          'Accept': 'application/json',
        },
      });
      const data = await response.json();
      if (data.ok) {
        setShowSnackbar(true);
        setTimeout(() => setShowSnackbar(false), 5000);
        setShowModal(false);
        // Here you can show the form data and selected plan title
        console.log(formData);
        console.log(selectedPlan.title);
      } else {
        alert('Oops! There was a problem with your submission.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending message.');
    }
  };

  const pricingPlans = [
    {
      title: "Basic App",
      cost: "$499+",
      features: [
        "Basic Functionality",
        "UI/UX Design",
        "Deployment Included",
        "App Store Submission",
        "Basic Support",
        "Basic Analytics",
        "Basic Security",
      ],
    },
    {
      title: "Pro App",
      cost: "$999+",
      features: [
        "Advanced Functionality",
        "Enhanced UI/UX Design",
        "Deployment Included",
        "App Store Submission",
        "Premium Support",
        "Advanced Analytics",
        "Enhanced Security",
        "Custom Features",
      ],
    },
    {
      title: "Enterprise App",
      cost: "Contact Us",
      features: [
        "Full Customization",
        "Priority Support",
        "Full Analytics",
        "Complete Security",
        "Third-Party Integration",
        "Maintenance & Updates",
        "Custom Solutions",
      ],
    },
  ];

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setFormData({ ...formData, pricingPlanTitle: plan.title }); // Update form data with selected plan title
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div>
      <div id="main-content">
        <Container fluid className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5" style={{ backgroundColor: 'black' }}>
          <div>
            <h1>Mobile Applications</h1>
            <p>Enhance your brand with a custom Mobile App.</p>
          </div>
        </Container>

        {/* Pricing Cards */}
        <Container className="my-5">
          <h1 className="text-center pricing-table-title">SELECT A PLAN</h1>
          <Row>
            {pricingPlans.map((plan, index) => (
              <Col md={12} lg={4}  key={index}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={cardVariants}
                >
                  <Card className={`pricing-card ${index === 1 ? 'pricing-card-highlighted' : ''}`}>
                    <Card.Body>
                      <FontAwesomeIcon icon={index === 0 ? faMobileAlt : index === 1 ? faLaptopCode : faProjectDiagram} className="pricing-plan-icon" />
                      <p className="pricing-plan-title">{plan.title}</p>
                      <h3 className="pricing-plan-cost ml-auto">{plan.cost}</h3>
                      <Button onClick={() => handlePlanSelect(plan)} className="pricing-plan-purchase-btn">Select Plan</Button>
                      <Container className="pricing-plan-features-container">
                        <ul className="pricing-plan-features">
                          {plan.features.map((feature, idx) => (
                            <li key={idx} className="pricing-plan-feature-item">
                              <FontAwesomeIcon icon={faCheckCircle} className="text-primary icon-pricing" /> {feature}
                            </li>
                          ))}
                        </ul>
                      </Container>
                    </Card.Body>
                  </Card>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>

        {/* Features Section */}
        <Container className="mt-5 p-5 bg-light border rounded pricing-con">
          <Row className="justify-content-center">
            <h3 className="what-you text-center mb-4">What You Get with Our Mobile Applications</h3>
          </Row>
          <Row className="feature-list">
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faMobileAlt} size="3x" className="mb-3" />
                <h4>Cutting-Edge Technology</h4>
                <p>Utilize the latest in mobile technologies to ensure your application is fast, reliable, and secure. Our team stays up-to-date with industry trends and advancements, providing you with innovative solutions that leverage the best tools and practices available.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faLaptopCode} size="3x" className="mb-3" />
                <h4>Responsive Design</h4>
                <p>Ensure a seamless experience across all devices with designs that adapt to any screen size. Our responsive designs guarantee that your mobile application looks and functions perfectly on all devices, providing users with an optimal experience no matter how they access your app.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faHeadset} size="3x" className="mb-3" />
                <h4>24/7 Support</h4>
                <p>Receive round-the-clock support from our team, keeping your mobile application running smoothly. Our dedicated support team is available 24/7 to assist with any issues or questions you may have, ensuring your app remains functional and up-to-date at all times.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faCogs} size="3x" className="mb-3" />
                <h4>Custom Development</h4>
                <p>Get a tailored solution that fits your unique business needs and goals. Our custom development services ensure that your app is not just another template but a unique digital asset, reflecting your brand’s identity and meeting your specific requirements.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faUsers} size="3x" className="mb-3" />
                <h4>User-Friendly Interface</h4>
                <p>Provide your users with an intuitive and easy-to-navigate interface. Our designs focus on usability, making it simple for users to find the information they need quickly and efficiently, which enhances their overall experience and encourages repeat use.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faShieldAlt} size="3x" className="mb-3" />
                <h4>Security</h4>
                <p>Implement robust security features to protect your application and user data. We prioritize your app’s security by incorporating advanced security measures such as encryption, regular updates, and proactive monitoring to safeguard against potential threats.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faSyncAlt} size="3x" className="mb-3" />
                <h4>Scalability</h4>
                <p>Build an app that can grow with your business and handle increasing user demands. Our scalable solutions ensure that your app can adapt to your business’s evolving needs, accommodating more traffic, content, and functionalities as you expand.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faProjectDiagram} size="3x" className="mb-3" />
                <h4>Integration</h4>
                <p>Seamlessly integrate your app with existing systems and third-party services. Whether it's payment gateways, CRM systems, or social media channels, our integration services ensure that your app functions harmoniously with all your business tools and services.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faChartLine} size="3x" className="mb-3" />
                <h4>Analytics</h4>
                <p>Gain insights into user behavior and app performance with comprehensive analytics tools. Our analytics integration allows you to track key metrics, analyze user interactions, and make data-driven decisions to improve your app’s performance and user satisfaction.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faTools} size="3x" className="mb-3" />
                <h4>Maintenance & Updates</h4>
                <p>Keep your application running smoothly with regular maintenance and updates. Our ongoing maintenance services include software updates, performance optimization, and content management to ensure your app remains in top condition and continues to meet your business goals.</p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Contact Form Modal */}
      <ContactForm
        show={showModal}
        handleClose={handleClose}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        selectedPlan={selectedPlan}
      />

      {/* Snackbar for submission message */}
      {showSnackbar && (
        <div className="snackbar">
          Submission successful!
        </div>
      )}
    </div>
  );
};

export default MobilePricing;
