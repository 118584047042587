import React from 'react';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const ContactForm = ({ show, handleClose, formData, handleChange, handleSubmit, selectedPlan }) => {
  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton className="contact-modal-header">
        <Modal.Title className="contact-modal-title">Selected Plan Contact Form</Modal.Title>
      </Modal.Header>
      <Modal.Body className="contact-modal-body">
        <Row>
          <Col md={6} className="selected-plan-details">
            <h3 className="selected-plan-title">{selectedPlan.title}</h3>
            <p className="selected-plan-cost">{selectedPlan.cost}</p>
            {selectedPlan.features && (
              <Container className="d-flex justify-content-center">
                <ul className="selected-plan-features text-left">
                  {selectedPlan.features.map((feature, index) => (
                    <li key={index} className="selected-plan-feature-item">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-primary icon-pricing" /> {feature}
                    </li>
                  ))}
                </ul>
              </Container>
            )}
          </Col>
          <Col md={6}>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName" className="contact-form-group price-name">
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Full Name"
                  className="contact-form-control"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhone" className="contact-form-group">
                <Form.Control
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone Number"
                  className="contact-form-control"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="contact-form-group">
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email Address"
                  className="contact-form-control"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBusinessName" className="contact-form-group">
                <Form.Control
                  type="text"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                  placeholder="Business Name"
                  className="contact-form-control"
                />
              </Form.Group>
              <Form.Group controlId="formMessage" className="contact-form-group">
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  className="contact-form-control"
                />
              </Form.Group>
              <Form.Control
                type="hidden"
                name="pricingPlanTitle"
                value={formData.pricingPlanTitle}
              />
              <div className="submit-btn-container">
                <Button variant="primary" type="submit" className="contact-submit-btn">
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ContactForm;
