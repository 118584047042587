import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { useForm, ValidationError } from '@formspree/react';
import './contact.css';

const handleSubmitVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
};

function Contact() {
    const [state, handleSubmit] = useForm("mpzvklpd");
    const [contactType, setContactType] = useState("General Inquiry");

    const handleContactType = (e) => {
        setContactType(e.target.value);
    };

    return (
        <>
            <Container fluid className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5">
                <div>
                    <h1>Contact Us</h1>
                    <p>Your trusted partner in Digital Transformation.</p>
                </div>
            </Container>
            <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.6, ease: 'easeOut' }}>
                <Container className="contact-form-container my-5">
                    <Row className="mb-4">
                        <Col>
                            <h2 className="section-header">GET IN TOUCH</h2>
                        </Col>
                    </Row>
                    {state.succeeded ? (
                        <motion.div
                            key="thank-you"
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={handleSubmitVariants}
                            className="paper-like-container text-center thank-you-message contact-page-message"
                        >
                            <h3 className="thank-you-title">Thank you for reaching out!</h3>
                            <p className="thank-you-text">Your message has been successfully submitted.</p>
                            <p className="thank-you-text">We appreciate your interest and will get back to you promptly.</p>
                            <div className="email-response">
                                <h4 className="response-title">In the meantime, here's what to expect:</h4>
                                <ul className="response-list">
                                    <li>Confirmation email has been sent to the provided address.</li>
                                    <li>Your inquiry is being reviewed by our team of experts.</li>
                                    <li>We will respond to your message within 24-48 hours.</li>
                                </ul>
                            </div>
                        </motion.div>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6} xs={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="name">Full Name *</Form.Label>
                                        <Form.Control
                                            id="name"
                                            type="text"
                                            name="name"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="email">Email Address *</Form.Label>
                                        <Form.Control
                                            id="email"
                                            type="email"
                                            name="email"
                                            required
                                        />
                                        <ValidationError
                                            prefix="Email"
                                            field="email"
                                            errors={state.errors}
                                        />                                    
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="phone">Phone Number *</Form.Label>
                                        <Form.Control
                                            id="phone"
                                            type="tel"
                                            name="phone"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6} xs={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="contactType">Contact Type *</Form.Label>
                                        <Form.Select
                                            id="contactType"
                                            name="contactType"
                                            value={contactType}
                                            onChange={handleContactType}
                                            required
                                        >
                                            <option value="General Inquiry">General Inquiry</option>
                                            <option value="Website Development">Website Development</option>
                                            <option value="App Development">App Development</option>
                                            <option value="Custom Software">Custom Software</option>
                                            <option value="Digital Marketing">Digital Marketing</option>
                                            <option value="Graphic Design">Graphic Design</option>
                                            <option value="Customer Support">Customer Support</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="message">Message *</Form.Label>
                                        <Form.Control
                                            id="message"
                                            as="textarea"
                                            rows="4"  // Reduced height of the message box
                                            name="message"
                                            className='mt-3'
                                            required
                                        />
                                        <ValidationError
                                            prefix="Message"
                                            field="message"
                                            errors={state.errors}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" disabled={state.submitting} style={{ fontWeight: '550', minWidth: '100px' }}>
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Container>
            </motion.div>
        </>
    );
}

export default Contact;
