import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './insurance-feat.css';

const InsuranceServices = () => {
    const features = [
        {
            title: "Direct Lead Capture",
            description: "Ensure every quote submission is sent directly to you, eliminating competition from other agents and maximizing your sales potential.",
            iconName: "bi-graph-up-arrow" // Represents lead generation and growth
        },
        {
            title: "Optimized Online Presence",
            description: "Boost your visibility online with Google and Bing profile optimization, on-site SEO, and targeted online branding strategies.",
            iconName: "bi-globe" // Emphasizes global reach and online presence
        },
        {
            title: "Efficient Quote Forms",
            description: "Simplify the quoting process with quick, user-friendly forms designed to convert visitors into clients in under a minute.",
            iconName: "bi-file-earmark-check" // Represents documents and form efficiency
        },
        {
            title: "Direct Quote Submissions",
            description: "Receive all quote requests directly, avoiding competition from multiple agents in your area like provider websites do.",
            iconName: "bi-envelope-check" // Represents direct communication and lead capture
        },
        
        {
            title: "Comprehensive Digital Marketing",
            description: "Engage potential clients with strategic digital marketing services, including social media management, blog content, and YouTube videos.",
            iconName: "bi-megaphone" // Reflects marketing and promotion
        }
    ];

    return (
        <div className="container" style={{ marginTop: '5rem', marginBottom: '5rem' }}>
            <div className="custom-services-subheader">
                <h3>Insurance Services</h3>
                <span className="custom-line-icon"></span>
            </div>

            <div className="custom-wwo-section mt-3 mb-5">
                <h2>Top Five App Features                </h2>
                < p className='insurance-feat'>Explore the unique features designed to maximize your reach, capture direct leads, and enhance client engagement for your insurance agency.</p>
            </div>

            <Row xs={1} md={2} lg={5} className="g-4">
                {features.map((feature, idx) => (
                    <Col key={idx}>
                        <Card className="divine-feature-card">
                        <Card.Body>
                            <i className={`bi ${feature.iconName} divine-feature-icon mb-2`} aria-hidden="true"></i>
                            <Card.Title className='about-feature-cards'>{feature.title}</Card.Title>
                            <Card.Text>{feature.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default InsuranceServices;
