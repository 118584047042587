import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import '../blog-folder/blog.css';
import image1 from "../blog-folder/insurance.webp";
import image2 from "../blog-folder/patis.webp";
import image3 from "../blog-folder/convert.webp";

// Blog post data
const blogPosts = [
  {
    id: 1,
    title: 'Insurance Websites',
    date: 'September 10, 2024',
    description: 'Learn how our customized websites streamline insurance quotes and boost leads, featuring a success story with Farmers Insurance.',
    imageUrl: image1,
    link: '/case-study1',
  },
  {
    id: 2,
    title: '3rd Annual Patis Cup',
    date: 'July 16, 2024',
    description: 'Discover the highlights of the 3rd Farmers Patis Cup Charity Golf Tournament, hosted by Patrick Rodriguez, featuring fun and community support.',
    imageUrl: image2,
    link: '/case-study2',
  },
  {
    id: 3,
    title: 'Grow Organically',
    date: 'June 25, 2024',
    description: 'Learn how Divine Software Systems helps businesses grow with expert SEO, engaging websites, and targeted digital marketing.',
    imageUrl: image3,
    link: '/case-study3',
  },
  
  // Additional blog posts can go here
];

const Blog = () => {
  const [showModal, setShowModal] = useState(false);

  // Handle closing the modal
  const handleClose = () => {
    setShowModal(false);
    document.getElementById('content').classList.remove('blur-background'); // Remove blur
    document.body.style.overflow = 'auto'; // Re-enable scroll on body when modal is closed
  };

  // Handle showing the modal
  const handleShow = () => {
    setShowModal(true);
    document.getElementById('content').classList.add('blur-background'); // Apply blur to the background content
    document.body.style.overflow = 'hidden'; // Prevent body scroll when modal is open
  };

  // Ensure cleanup if the component is unmounted
  useEffect(() => {
    return () => {
      const contentElement = document.getElementById('content');
      if (contentElement) {
        contentElement.classList.remove('blur-background'); // Remove blur when unmounted
      }
      document.body.style.overflow = 'auto'; // Ensure scroll is enabled when component unmounts
    };
  }, []);

  return (
    <>
      <div id="content">
        <Container fluid className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5">
          <div>
            <h1 className="display-4 fw-bold">Our Blog</h1>
            <p className="lead">Your trusted partner in Digital Transformation.</p>
          </div>
        </Container>

        <div className="blog-section-container">
          <Container>
            <Row>
              {blogPosts.slice(0, 3).map((post) => (
                <Col md={12} lg={4} key={post.id} className="mb-4">
                  <div className="blog-card">
                    <div className="blog-card-img-holder">
                      <img src={post.imageUrl} alt={post.title} />
                    </div>
                    <h3 className="blog-card-title">{post.title}</h3>
                    <span className="blog-card-time">{post.date}</span>
                    <p className="blog-card-description">{post.description}</p>
                    <div className="blog-card-options">
                      <Button className="blog-card-btn" href={post.link}>
                        Read More
                      </Button>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        <div className="d-flex justify-content-end m-4">
          <Button onClick={handleShow} className="see-all-btn">
            See All Blogs
          </Button>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose} size="lg" dialogClassName="wide-modal" centered scrollable>
        <Modal.Header closeButton>
          <Modal.Title className='blog-modal-title'>All Blogs</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}> {/* Allow scrolling within the modal */}
          <Container>
            <Row>
              {blogPosts.map((post) => (
                <Col key={post.id} md={12} className="mb-4">
                  <div className="blog-card">
                    <h3 className="blog-card-title">{post.title}</h3>
                    <span className="blog-card-time">{post.date}</span>
                    <p className="blog-card-description">{post.description}</p>
                    <div className="blog-card-options">
                      <Button className="blog-card-btn" href={post.link}>
                        Read Blog
                      </Button>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Blog;
