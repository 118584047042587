import React, { useState } from 'react';
import { setCookie, getCookie, removeCookie } from './ManageCookies';
import './cookies.css';  // Ensure this is the correct path to your CSS file

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(!getCookie('consent'));

  const handleConsent = () => {
    setCookie('consent', 'true', { expires: 365 });
    setShowBanner(false);
  };

  const handleDecline = () => {
    removeCookie('consent');
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-consent-container">
      <p className="cookie-text">
        This website uses cookies to enhance your experience.
      </p>
      <div className="button-container">
        <button
          className="consent-button accept-button"
          onMouseOver={e => {
            e.currentTarget.classList.add('hover');
          }}
          onMouseOut={e => {
            e.currentTarget.classList.remove('hover');
          }}
          onClick={handleConsent}
        >
          Accept
        </button>
        <button
          className="consent-button decline-button"
          onClick={handleDecline}
        >
          Decline
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
