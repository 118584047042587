import React from 'react';
import { Row, Col } from 'react-bootstrap';
import logo from './logo.webp';  // Ensure to update the path to your logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import './footer.css';
import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-container">
      <div className="footer-card">
        <Row className="mb-4">
          <Col md={6} sm={6} xs={12}>
            <div className="footer-text pull-left">
              <div className="d-flex align-items-center">
                <img src={logo} alt="Divine Software Systems" className="footer-logo" />
              </div>
              <div className="social mt-4 mb-3">
                <a href="https://www.facebook.com/profile.php?id=61560558546460&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} className="fa-lg" />
                </a>
                <a href="https://www.instagram.com/divinesoftwaresystems?igsh=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="fa-lg" />
                </a>
                <a href="https://www.linkedin.com/company/divine-software-systems/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} className="fa-lg" />
                </a>
              </div>
            </div>
          </Col>
          <Col md={6} sm={6} xs={12} className="contact-us-column ml-auto">
            <h5 className="heading-footer">Contact Us</h5>
            <p><FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 foot-contact" /> Retail C, BM ONE, Ninoy Aquino Ave, Paranaque, Metro Manila </p>
            <p><FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 foot-contact" /> 37675 Niles Blvd, Fremont, CA 94536 </p>
            <p><FontAwesomeIcon icon={faEnvelope} className="me-2 foot-contact" /> hello@divinesoftwaresystems.com</p>
            <p><FontAwesomeIcon icon={faPhone} className="me-2 foot-contact" /> (310)-503-3805 </p>
          </Col>
        </Row>
        <div className="divider mb-4"></div>
        <Row style={{ fontSize: '10px' }}>
          <Col md={12} sm={12} xs={12} className="d-flex justify-content-between">
            <p className="footer-style mb-0">© {currentYear} Divine Software Systems LLC. All Rights Reserved.</p>
            <div>
  <Link to="/terms-of-service" className="footer-style">Terms of Service </Link> | 
  <Link to="/privacy-policy" className="footer-style"> Privacy Policy</Link>
</div>

          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
