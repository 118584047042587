import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion, useAnimation, useInView } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faCheckCircle, faCode, faUsers, faClock, faHeart, faBriefcase, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './about.css';

import Img1 from './josh1.webp';
import Img2 from './Justin1.webp';

gsap.registerPlugin(ScrollTrigger);

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.4,
    },
  },
};

const wordVariants = {
  hidden: (direction) => ({
    opacity: 0,
    x: direction === 'left' ? -60 : 60,
  }),
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 70,
      damping: 10,
    },
  },
};

const iconVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.3,
      type: 'spring',
      stiffness: 70,
      damping: 10,
    },
  },
};

const counterVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.8,
      ease: 'easeInOut',
    },
  },
};

const features = [
  {
    title: "High-Quality Solutions",
    description: "We deliver top-notch solutions meticulously tailored to your specific needs.",
    icon: faCheckCircle,
  },
  {
    title: "Latest Technologies",
    description: "Our team utilizes the latest technologies and adheres to best industry practices.",
    icon: faCode,
  },
  {
    title: "Dedicated Team",
    description: "Our team of experts is fully committed to ensuring your project's success.",
    icon: faUsers,
  },
  {
    title: "On-Time Delivery",
    description: "We guarantee that your project will be completed within the agreed schedule.",
    icon: faClock,
  },
  {
    title: "No Egos",
    description: "We collaborate effectively, without egos, to achieve the best possible results.",
    icon: faHeart,
  },
];

export default function ScrollEffect() {
  const component = useRef();
  const scrollDownRef = useRef();
  const [count, setCount] = useState(0);
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
      let interval = setInterval(() => {
        setCount((prevCount) => (prevCount < 20 ? prevCount + 1 : 20));
      }, 100);
      return () => clearInterval(interval);
    }
  }, [controls, inView]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to('.icon-animation', {
        y: -10,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
        duration: 1,
      });

      gsap.utils.toArray('.scroll-element').forEach((element) => {
        if (
          !element.closest('.header-section') &&
          !element.closest('.backstory-section') &&
          !element.closest('.vision-section') &&
          !element.closest('.choose-us-section') &&
          !element.closest('.offer-section')
        ) {
          gsap.fromTo(
            element,
            { y: 50, opacity: 0 },
            {
              y: 0,
              opacity: 1,
              duration: 1,
              ease: 'power1.out',
              scrollTrigger: {
                trigger: element,
                start: 'top 90%',
                end: 'top 60%',
                scrub: false,
              },
            }
          );
        }
      });

      
    }, component);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={component}>
      <section className="header-section about-container">
        <Container className="text-center">
          <motion.div
            className="text-container"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <motion.div className="word" custom="left" variants={wordVariants}>
              <span className="highlight">D</span>IVINE
            </motion.div>
            <motion.div className="word" custom="right" variants={wordVariants}>
              <span className="highlight">S</span>OFTWARE
            </motion.div>
            <motion.div className="word" custom="left" variants={wordVariants}>
              <span className="highlight">S</span>YSTEMS
            </motion.div>
          </motion.div>
          <motion.div
            className="learn-more"
            initial="hidden"
            animate="visible"
            variants={iconVariants}
            ref={scrollDownRef}
          >
            <div className="circle-icon icon-animation">
              <FontAwesomeIcon icon={faArrowDown} size="lg" />
            </div>
            <span className="who-we">WHO WE ARE</span>
          </motion.div>
        </Container>
      </section>

      <section className="backstory-section">
        <Container>
          <div className="custom-services-subheader">
            <h3>About Us</h3>
            <span className="custom-line-icon"></span>
          </div>
          <div className="custom-wwo-section mt-3 mb-5 feature-accordian-1 ">
            <h2>Our Backstory</h2>
          </div>
          <Row className="align-items-center">
            <Col md={12} lg={6} className="about-col position-relative">
              <h1 className="about-head1">How It Started</h1>
              <p className="sadboi">
                Divine Software Systems was founded by two friends with a shared passion for software development. What began as a hobby quickly evolved into a deep love for technology, driven by the excitement of transforming our creative visions into reality. Today, we've grown into a dedicated and dynamic team, channeling our enthusiasm and expertise into delivering exceptional software solutions. Our journey from casual projects to a thriving business reflects our commitment to innovation and excellence in the digital landscape.
              </p>
            </Col>
            <Col md={12} lg={6} className="about-col">
              <h1 className="about-head">We're Growing</h1>
              <hr />
              <div className="date-counter" ref={ref}>
                <Row>
                  <Col>
                    <p className="small-text">Established</p>
                  </Col>
                  <Col>
                    <p className="small-text">Team Size</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <motion.h1
                      className="counter"
                      initial={{ opacity: 0 }}
                      animate={controls}
                      variants={counterVariants}
                      style={{ color: 'black' }}
                    >
                    2024
                    </motion.h1>
                  </Col>
                  <Col>
                    <motion.h1
                      className="counter"
                      initial={{ opacity: 0 }}
                      animate={controls}
                      variants={counterVariants}
                      style={{ color: 'black' }}
                    >
                      {count >= 20 ? '20+' : count}
                    </motion.h1>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="black-section">
        <Container>
          <Row className="align-items-center">
            <Col md={3} className="creator-image-col">
              <img src={Img1} alt="Joshua Lopez" className="creator-image" />
            </Col>
            <Col md={9} className="creator-info-col">
              <h2 className="creator-head">Co-Founder</h2>
              <p className="creator-bio">
                Joshua Lopez, who's from Los Angeles, CA. Co-founded Divine Software Systems with a passion for applying technological innovations to real-world challenges. His dedication to digital innovation drives the company's focus on custom software solutions, aiming to transform how businesses engage with technology. Joshua's vision ensures that Divine Software Systems provides cutting-edge solutions to meet clients' needs.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="offers-vision-section">
        <Container>
          <div className="custom-services-subheader">
            <h3>About Us</h3>
            <span className="custom-line-icon"></span>
          </div>
          <div className="custom-wwo-section mt-3 mb-5 feature-accordian-1 ">
            <h2>What Sets Us Apart</h2>
          </div>
          <Row>
            <Col md={12} lg={6} className="offer-section">
              <Card className="offer-content">
                <Card.Body>
                  <Card.Title className="section-head">
                    <FontAwesomeIcon icon={faBriefcase} className="section-icon" /> What We Offer
                  </Card.Title>
                  <Card.Text className="section-bio">
                    At Divine Software Systems, we provide a comprehensive range of services including web development, app development, SEO optimization, and custom software solutions. Our dedicated team works closely with clients to understand their unique needs and deliver tailored solutions that drive success.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} lg={6} className="vision-section">
              <Card className="vision-content">
                <Card.Body>
                  <Card.Title className="section-head">
                    <FontAwesomeIcon icon={faBullseye} className="section-icon" /> Our Vision
                  </Card.Title>
                  <Card.Text className="section-bio">
                    Our vision is to be a leader in the software development industry by delivering efficient solutions that help businesses grow and be successful. We strive to create a collaborative and inclusive environment where creativity and technology come together to solve complex challenges and help create value for our clients.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="black-section">
        <Container>
          <Row className="align-items-center">
            <Col md={3} className="creator-image-col">
              <img src={Img2} alt="Justin Rodriguez" className="creator-image" />
            </Col>
            <Col md={9} className="creator-info-col">
              <h2 className="creator-head">Co-Founder</h2>
              <p className="creator-bio">
                Justin Rodriguez, who's from Hayward, CA. Co-launched Divine Software Systems with a vision to integrate cutting-edge technology and strategic business approaches. His commitment to addressing complex digital challenges underscores the company's dedication to providing bespoke software services tailored to diverse client needs, keeping Divine Software Systems at the forefront of digital innovation.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="choose-us-section">
        <Container fluid>
          <Row className="text-center mb-4">
            <Col>
              <h2 className="choose-us-head">Why You Should Choose Us</h2>
              <p className="choose-us-bio">
                At Divine Software Systems, we are dedicated to delivering high-quality solutions that meet the unique needs of each client. Our team of experts uses the latest technologies and best practices to ensure that your project is not only completed on time but also exceeds your expectations. From initial consultation to final delivery, we are committed to your success.
              </p>
            </Col>
          </Row>
          <Row xs={1} md={2} lg={5} className="g-4">
            {features.map((feature, idx) => (
              <Col key={idx}>
                <Card className="divine-feature-card">
                  <Card.Body>
                    <FontAwesomeIcon icon={feature.icon} className="divine-feature-icon mb-2" />
                    <Card.Title className='about-feature-cards'>{feature.title}</Card.Title>
                    <Card.Text>{feature.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
}
