import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { motion } from 'framer-motion';
import img1 from './home.webp';
import img2 from './services.webp';
import img3 from './blog.webp';
import img4 from './quote.webp';
import img5 from './about.webp';

const fadeUpVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } // Increased duration to 0.8 seconds
};

const insuranceSections = () => {
    return (
        <Container className="my-5">
            <div className="custom-services-subheader">
                <h3>Divine Insurance Solutions</h3><span className="custom-line-icon"></span>
            </div>

            <div className="custom-wwo-section mt-3 mb-5">
                <h2>Our Insurance Platform</h2>
            </div>

            {/* User-Friendly Interface */}
            <Row className="divine-sections align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img1} alt="User-Friendly Interface" fluid className='divine-img' />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2 >#1 User-Friendly Interface</h2>
                        <p >Our platform is designed to make it easy for clients to request quotes and access information, thanks to:</p>
                        <ul className="bullet-points-left">
                            <li><strong>Intuitive Navigation:</strong> Clients can find what they need quickly with a clear, straightforward layout.</li>
                            <li><strong>Easy-to-Use Forms:</strong> Streamlined quote forms enhance client experience and increase submission rates.</li>
                            <li><strong>Consistent Design:</strong> A unified look and feel across all features ensure a seamless experience.</li>
                            <li><strong>Accessibility Features:</strong> Options like text-to-speech and high-contrast modes accommodate all users.</li>
                        </ul>
                    </motion.div>
                </Col>
            </Row>

            {/* Secure and Efficient Quotes */}
            <Row className="align-items-center mb-custom section-light-gray-1">
                <Col lg={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2 >#2 Secure and Efficient Quotes</h2>
                        <p className='insurance-aligned'>Ensure every quote request is managed securely and efficiently with direct communication channels:</p>
                        <ul className="bullet-points-left">
                            <li><strong>Direct Submissions:</strong> Every quote form submitted is instantly sent directly to you, bypassing other agents and reducing competition.</li>
                            <li><strong>Automated Confirmation:</strong> Clients receive immediate confirmation emails, enhancing trust and transparency.</li>
                            <li><strong>Instant Notifications:</strong> Stay informed with instant notifications whenever a new quote is submitted.</li>
                        </ul>
                    </motion.div>
                </Col>
                <Col lg={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                    <Image src={img2} alt="Secure and Efficient Quotes" fluid className='divine-img' />
                </Col>
            </Row>

            {/* Enhanced Visibility */}
            <Row className="align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img3} alt="Enhanced Visibility" fluid className='divine-img' />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#3 Enhanced Visibility</h2>
                        <p>Our platform makes it easier for clients to find your website online, driving more traffic and potential leads:</p>
                        <ul className="bullet-points-left">
                            <li><strong>SEO Backed by Google Lighthouse:</strong> Optimized for top search engine rankings to ensure your website appears first when your name is searched.</li>
                            <li><strong>Regular Blog Updates:</strong> Fresh, relevant content helps improve search visibility and client engagement.</li>
                            <li><strong>Strategic Content Marketing:</strong> Targeted content strategies boost your online presence and credibility.</li>
                        </ul>
                    </motion.div>
                </Col>
            </Row>

            {/* Paperless Document Handling */}
            
            <Row className="align-items-center mb-custom section-light-gray-1">
                <Col lg={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                    <h2>#4 Paperless Document Handling</h2>
                    <p>Our platform eliminates paper-based tasks, making your operations more efficient and environmentally friendly:</p>
                        <ul className="bullet-points-left">
                            <li><strong>Digital Documentation:</strong> Move all forms and client details to secure digital storage.</li>
                            <li><strong>Efficient Workflow:</strong> Reduce time spent on manual paperwork and focus on client engagement.</li>
                            <li><strong>Reliable Access:</strong> Easily retrieve and manage documents without the risk of loss or damage.</li>
                        </ul>
                    </motion.div>
                </Col>
                <Col lg={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                <Image src={img4} alt="Paperless Document Handling" fluid className='divine-img' />
                </Col>
            </Row>

            {/* Customization Options */}
            <Row className="divine-sections align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img5} alt="Customization Options" fluid className='divine-img' />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#5 Customization Options</h2>
                        <p>Our platform is fully customizable to match the unique needs of your insurance business:</p>
                        <ul className="bullet-points-left">
                            <li><strong>Personalized Features:</strong> Adapt forms and functionalities to align with your specific processes.</li>
                            <li><strong>Modular Choices:</strong> Choose and pay only for the features you need, maximizing efficiency.</li>
                            <li><strong>User Preferences:</strong> Customize the interface to fit different roles within your organization.</li>
                        </ul>
                    </motion.div>
                </Col>
            </Row>
        </Container>
    );
};

export default insuranceSections;
