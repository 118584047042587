import React, { useRef } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import './homepage.css';
import logoUrl from '../Assets/image-7.webp';
import CardsComponent from '../homepage-1/cardcompoenent';
import Wwo from '../homepage-2/wwo';
import Client from '../client-row/client';
import Contact from '../home-contact/homecontact';
import ClientsCarousel from '../clients-folder/clients';

const sentenceVariant = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.3,
      staggerChildren: 0.028,
    },
  },
};

const letterVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const paragraphVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 2.0,
    },
  },
};

const buttonVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 2.3,
    },
  },
};

const logoSlideVariant = {
  hidden: { x: 200, opacity: 0 },  // Start off-screen to the right
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 80,  // Increased stiffness for a faster yet smooth effect
      damping: 20,  // Slightly reduced damping to accommodate faster movement
      delay: 1.8,  // Synced delay with the paragraph's visibility
    },
  },
};

const HomepageComponent = () => {
  const contactRef = useRef(null);

  const scrollToContact = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="h-100"> {/* Ensure this div takes the full height */}
      <Container fluid className="h-100 d-flex align-items-center justify-content-center homepage-container">
        <Row className="align-items-center text-center">
          <Col xs={12} lg={6} className="order-2 order-md-1">
            <div className="mission-statement">
              <motion.h1
                className="responsive-title" // Added responsive class here
                variants={sentenceVariant}
                initial="hidden"
                animate="visible"
              >
                { "Tech-Driven Success: Elevating Businesses to New Heights".split("").map((char, index) => (
                  <motion.span key={char + "-" + index} variants={letterVariant}>
                    {char}
                  </motion.span>
                ))}
              </motion.h1>
              <motion.p
                variants={paragraphVariant}
                initial="hidden"
                animate="visible"
              >
                Unlock growth and efficiency with our software development solutions, tailored for today's competitive market.
              </motion.p>
              <motion.div
                variants={buttonVariant}
                initial="hidden"
                animate="visible"
              >
                <Button variant="primary" className="mt-3 mega-button" onClick={scrollToContact}>
                  Improve Your Business Today
                </Button>
              </motion.div>
            </div>
          </Col>
          <Col xs={12} lg={6} className="order-1 order-md-2">
            <motion.div
              variants={logoSlideVariant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <Image src={logoUrl} alt="Divine Software Systems Logo" className="logo-image my-3 my-md-0" />
            </motion.div>
          </Col>
        </Row>
      </Container>
      <Wwo />
      <Client />
      <CardsComponent />
      <ClientsCarousel />
      <div ref={contactRef}>
        <Contact />
      </div>
      
    </div>
  );
};

export default HomepageComponent;
