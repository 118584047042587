import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './case.css';

const TournamentBlogPost = () => {
    return (
        <>
            {/* Jumbotron */}
            <Container fluid className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5">
                <div>
                    <h1>3rd Farmers Patis Cup Charity Tournament</h1>
                    <p>Learn more about whats going on with DSS

</p>
                </div>
            </Container>

            {/* Blog Post Details */}
            <Container className="case-study-section">
                <Row>
                    <Col md={8}>
                        <div className="case-study-header">
                            <h2>Event Highlights</h2>
                            <p className="blog-text1">3rd Farmers Patis Cup | Year: 2024 | Charity Event</p>
                        </div>

                        <div className="case-study-content">
                            <h3>About the Tournament</h3>
                            <p>The 3rd Farmers Patis Cup Charity Golf Tournament, hosted by Patrick Rodriguez, was a spectacular event held at Mission Hills Golf Course on July 26th, 2024. This 9-hole tournament brought together the community for a noble cause – supporting Villa Cristo Rey, a retirement and nursing home for sick and retired SVD missionaries at Christ the King Seminary in Oc. Phil.</p>

                            <h3>A Fun and Engaging Experience</h3>
                            <p>From the moment we arrived, the atmosphere was filled with excitement and camaraderie. It was a joy to see so many familiar faces from the community, all coming together to support a great cause. The event was not just about golf; it was about building relationships and having fun.</p>

                            <h3>Networking and Camaraderie</h3>
                            <p>Being around other business partners and sponsors such as Wings of Angels, TRANSPORT, Mabuhay, SBOVACOM, and Lizard Auto Glass was fantastic. It was a perfect opportunity to network, share experiences, and forge stronger connections. The spirit of collaboration and mutual support was evident throughout the day.</p>

                            <h3>Our Role and Experience</h3>
                            <p>As proud sponsors of the event, Divine Software Systems LLC was thrilled to provide comprehensive photography and videography services. Capturing the smiles, the competitive spirit, and the joyous moments was an absolute pleasure. Our team ensured that every highlight was documented, creating lasting memories for all participants.</p>

                            <h3>Memorable Moments</h3>
                            <p>The tournament was filled with unforgettable moments – from impressive swings to heartfelt speeches during the award ceremony. The excitement of the competition was matched only by the warmth of the fellowship during dinner. It was a day where fun, philanthropy, and friendship blended seamlessly.</p>

                            <h3>Looking Ahead</h3>
                            <p>We at Divine Software Systems LLC are committed to supporting our community and participating in meaningful events like the Farmers Patis Cup. We look forward to more opportunities to contribute and engage with our partners and friends in the future.</p>

                            <p>For more information about our community involvement or to view photos and videos from the event, visit our website or contact us. Let's continue to build strong, supportive communities together!</p>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="case-links-header">View Our Other Blog Posts</div>
                        <div className="case-links">
                        <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>2024 / Blog Post</Card.Title>
                                    <Card.Text>
                                        <a href='/case-study1'>Insurance Websites For Lead Generation</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>2024 / Blog Post</Card.Title>
                                    <Card.Text>
                                        <a href='/case-study3'>Boost Your Business Growth Organically
                                        </a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default TournamentBlogPost;
