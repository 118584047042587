import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './privacy.css';
const PrivacyPolicy = () => {
  return (
    <>
      <Container fluid className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5" style={{ backgroundColor: 'black' }}>
      <div> {/* Wrapper div for the text */}
        <h1>Privacy Policy</h1>
        <p>Your trusted partner in digital transformation.</p>
      </div>
    </Container>

      <Container className="privacy-details mt-4" style={{ textAlign: 'left' }}>  
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <div className="mt-4">
            <h1>Privacy Policy</h1>
<p>Last updated: April 21, 2024</p>
<p>This Privacy Policy describes the policies and procedures of Divine Software Systems (referred to as "the Company," "We," "Us," or "Our") regarding the collection, use, and disclosure of personal information when you use our website, and it explains your privacy rights and protections under the law.</p>
<h2>Interpretation and Definitions</h2>
<h3>Interpretation</h3>
<p>Words with initial capitalization have meanings defined under the following conditions. These definitions apply regardless of whether the terms appear in singular or plural.</p>
<h3>Definitions</h3>
<p>For the purposes of this Privacy Policy:</p>
<ul>
<li><strong>Company</strong>: Divine Software Systems, with its business address located at 5020 W125th Street, California, United States.</li>
<li><strong>Cookies</strong>: Small files placed on your computer, mobile device, or any other device by a website, containing details of your browsing history and other information.</li>
<li><strong>Google Analytics</strong>: A web analytics service provided by Google Inc. that tracks and reports website traffic.</li>
<li><strong>Service</strong>: Refers to the Divine Software Systems website, accessible at <a href="divinesoftwaresystems.com" rel="external nofollow noopener" target="_blank">divinesoftwaresystems.com</a>.</li>
<li><strong>Personal Data</strong>: Any information that relates to an identified or identifiable individual.</li>
<li><strong>Contact Forms</strong>: Forms on our website that allow visitors to reach out to us with inquiries or requests.</li>
<li><strong>You</strong>: The individual accessing or using our Service, or the company or other legal entity on whose behalf such individual is accessing or using the Service, as applicable.</li>
</ul>
<h2>Collection and Use of Personal Data</h2>
<h3>Types of Data Collected</h3>
<h4>Personal Data</h4>
<p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. This may include:</p>
<ul>
<li>Email address</li>
<li>First name and last name</li>
<li>Phone number</li>
<li>Address, State, Province, ZIP/Postal code, City</li>
<li>Usage Data</li>
</ul>
<h4>Usage Data</h4>
<p>We automatically collect usage data when you use our Service. This may include information such as your device's IP address, browser type, pages visited, time and date of visit, time spent on pages, and other diagnostic data.</p>
<h4>Cookies and Tracking Technologies</h4>
<p>We use Cookies and similar tracking technologies to track activity on our Service and store certain information. These technologies include:</p>
<ul>
<li><strong>Cookies:</strong> Small files placed on your device that allow us to track and analyze your usage of our Service. You can control or refuse the use of Cookies through your browser settings, but this may limit your ability to use certain features of our Service.</li>
<li><strong>Web Beacons:</strong> Electronic files embedded in our website or emails to track user interactions, such as page views or email opens.</li>
</ul>
<p>We use Cookies for various purposes, including:</p>
<ul>
<li>Necessary/Essential Cookies: To provide you with essential features and functionalities of our website.</li>
<li>Cookies Policy/Notice Acceptance Cookies: To identify if users have accepted the use of cookies on our website.</li>
<li>Functionality Cookies: To remember your preferences and provide you with a personalized experience.</li>
</ul>
<p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.</p>
<h3>Use of Personal Data</h3>
<p>We may use your Personal Data for the following purposes:</p>
<ul>
<li>To provide and maintain our Service</li>
<li>To manage your Account and provide you with access to our Service</li>
<li>To contact you regarding updates or informative communications related to our Service</li>
<li>To provide you with news, special offers, and information about our products and services</li>
<li>To manage your requests and inquiries</li>
<li>For data analysis, identifying usage trends, and improving our Service</li>
</ul>
<h3>Disclosure of Personal Data</h3>
<p>We may share your personal information in the following situations:</p>
<ul>
<li>With Service Providers to facilitate our Service and analyze usage</li>
<li>For business transfers in connection with a merger, sale of assets, or acquisition of our company</li>
<li>With Affiliates, business partners, or other users with your consent or as necessary to provide our Service</li>
<li>With law enforcement or other legal authorities if required to comply with legal obligations or protect against legal liability</li>
</ul>
<h3>Retention and Deletion of Personal Data</h3>
<p>We retain your Personal Data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law. You have the right to request deletion of your Personal Data, subject to certain legal obligations and exceptions.</p>
<h3>Security of Personal Data</h3>
<p>We take commercially reasonable measures to protect your Personal Data from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
<h2>Children's Privacy</h2>
<p>Our Service is not directed to children under the age of 13, and we do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided us with personal information, please contact us.</p>
<h2>Links to Other Websites</h2>
<p>Our Service may contain links to third-party websites not operated by us. We have no control over and assume no responsibility for the content or privacy practices of these websites. We advise you to review the privacy policies of any third-party sites you visit.</p>
<h2>Additional Information</h2>
<h3>Google Analytics</h3>
<p>We use Google Analytics, a web analytics service provided by Google Inc., to track and analyze website traffic. Google Analytics uses cookies and other tracking technologies to collect data about website usage, which is then used to generate reports and improve our website's performance and user experience. By using our website, you consent to the processing of data about you by Google in the manner and for the purposes set out above. For more information on Google's privacy practices and how Google uses data collected through Google Analytics, please refer to Google's Privacy Policy.</p>
<h3>Contact Forms</h3>
<p>Our website includes contact forms that allow visitors to reach out to us with inquiries or requests. When you submit a contact form, we collect the information you provide, such as your name, email address, and message content. We use this information solely for the purpose of responding to your inquiries and providing assistance. We do not use contact form submissions for marketing purposes unless explicitly consented to by the user.</p>
<h3>Third-Party Services</h3>
<p>We may use third-party service providers to facilitate our Service, perform Service-related services, or assist us in analyzing how our Service is used. These third parties may have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
<h3>Data Protection Rights</h3>
<p>You may have certain data protection rights under applicable privacy laws, such as the right to access, correct, or delete your personal information. If you wish to exercise any of these rights, please contact us using the contact information provided in this Privacy Policy. We will respond to your request within a reasonable timeframe and in accordance with applicable laws.</p>
<h3>International Data Transfers</h3>
<p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ from those of your jurisdiction. By providing us with your information, you consent to the transfer of your data to our servers and the processing of your data in accordance with this Privacy Policy.</p>
<h3>Changes to this Privacy Policy</h3>
<p>We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting the updated Privacy Policy on this page. We encourage you to review this Privacy Policy periodically for any updates or changes. Your continued use of our Service after any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the updated Privacy Policy.</p>
<h2>Contact Us</h2>
<p>If you have any questions about this Privacy Policy or our data practices, please contact us:</p>
<ul>
<li>By email: divinesoftwaresystems@gmail.com</li>
</ul>
            </div>

            {/* Additional sections can continue with the same pattern */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PrivacyPolicy;
