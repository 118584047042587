import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt, faRocket, faHandshake, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';

const InsuranceContact = () => {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleSubmitVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const formAppearVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
        duration: 0.5,
        ease: 'easeInOut'
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    try {
      const response = await fetch('https://formspree.io/f/xnqeldyn', {
        method: 'POST',
        body: new FormData(form),
        headers: {
          'Accept': 'application/json'
        },
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        alert('Oops! There was a problem with your submission.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending message.');
    }
  };

  return (
    <>
       <Container className="mt-5 py-5 bg-light border-top border-bottom">
        <Row className="justify-content-center">
          <Col md={12} className="text-center mb-4">
            <h1 className="display-45 new-add" style={{ fontWeight: '550' }}>Why Choose Us?</h1>
          </Col>
          <Col md={4} className="text-center mb-3 divine-para">
            <FontAwesomeIcon icon={faRocket} size="3x" className="mb-2" />
            <h3>Maximize Lead Capture</h3>
            <p>Our websites ensure all quote requests are sent directly to you, helping you convert leads without the competition from other agents.</p>
          </Col>
          <Col md={4} className="text-center mb-3 divine-para">
            <FontAwesomeIcon icon={faHandshake} size="3x" className="mb-2" />
            <h3>Customized Solutions</h3>
            <p>Get a fully tailored website optimized for your insurance business, boosting your visibility and making you easy to find online.</p>
          </Col>
          <Col md={4} className="text-center mb-3 divine-para">
            <FontAwesomeIcon icon={faHeadset} size="3x" className="mb-2" />
            <h3>Dedicated Support</h3>
            <p>Access 24/7 support to help you make the most of your website, ensuring smooth operation and maximum impact.</p>
          </Col>
        </Row>
      </Container>

      <Container fluid className="custom-container help-me">
        <Row className="justify-content-md-center">
          <Col sm={12} lg={6} xl={6} xxl={6} className="left-column">
            <h2 className="homeform-heading">Get in Touch</h2>
            <p className="contact-text1 small-text">
              We are a skilled team delivering custom websites, apps, graphic designs, and marketing solutions for your business.
            </p>
            <div className="contact-info">
            <p className="contact-detail-item">
              <span className="icon-circle"><FontAwesomeIcon icon={faPhone} className="contact-icon" /></span> 
              <span className="contact-detail">+1 (310)-503-3805</span>
            </p>
            <p className="contact-detail-item email-detail">
              <span className="icon-circle"><FontAwesomeIcon icon={faEnvelope} className="contact-icon" /></span> 
              <span className="contact-detail">hello@divinesoftwaresystems.com</span>
            </p>
            <p className="contact-detail-item">
              <span className="icon-circle"><FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" /></span> 
              <span className="contact-detail">
                US Office: <a href="https://www.google.com/maps/place/Divine+Software+Systems+LLC/@37.5763866,-121.979915,17z/data=!3m1!4b1!4m6!3m5!1s0x808fc13cb689ed09:0x2abc072afed6bcb8!8m2!3d37.5763866!4d-121.9773401!16s%2Fg%2F11y5l0w8my?entry=ttu" style={{ textDecoration: 'underline', color: 'inherit' }}>Fremont, CA</a>
              </span>
            </p>
            <p className="contact-detail-item">
              <span className="icon-circle"><FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" /></span> 
              <span className="contact-detail">
                PH Office: <a href="https://www.google.com/maps/place/BM+ONE+office+and+commercial+building/@14.4916087,120.9937898,17z/data=!4m16!1m9!3m8!1s0x3397cff3448fe381:0xf4f092d112e2d74f!2sBM+ONE+office+and+commercial+building!8m2!3d14.4916041!4d120.9937636!9m1!1b1!16s%2Fg%2F11txlr0tdy!3m5!1s0x3397cff3448fe381:0xf4f092d112e2d74f!8m2!3d14.4916041!4d120.9937636!16s%2Fg%2F11txlr0tdy?entry=ttu" style={{ textDecoration: 'underline', color: 'inherit' }}>Manila, PH</a>
              </span>      
            </p>
          </div>
          </Col>
          <Col sm={12} lg={6} xl={6} xxl={6}>
            <AnimatePresence mode="wait">
              {submitted ? (
                <motion.div
                  key="thank-you"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={handleSubmitVariants}
                  className="paper-like-container text-center thank-you-message"
                >
                  <h3 className="thank-you-title">Thank you for reaching out!</h3>
                  <p className="thank-you-text">Your message has been successfully submitted.</p>
                  <p className="thank-you-text">We appreciate your interest and will get back to you promptly.</p>
                  <div className="email-response">
                    <h4 className="response-title">In the meantime, here's what to expect:</h4>
                    <ul className="response-list">
                      <li>Confirmation email has been sent to the provided address.</li>
                      <li>Your inquiry is being reviewed by our team of experts.</li>
                      <li>We will respond to your message within 24-48 hours.</li>
                    </ul>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  key="form"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.3 }}
                  variants={formAppearVariants}
                  className="paper-like-container"
                >
                  <h2 className="form-header">Make an Appointment</h2>
                  <Form className="centered-form" onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} md={6} className="mb-3">
                        <Form.Group>
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control type="text" placeholder="Full Name" className="paper-like-input" name="name" value={formData.name} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} className="mb-3">
                        <Form.Group>
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control type="text" placeholder="Phone Number" className="paper-like-input" name="phone" value={formData.phone} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control type="email" placeholder="Email Address" className="paper-like-input" name="email" value={formData.email} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" rows={5} placeholder="Message" className="paper-like-input" name="message" value={formData.message} onChange={handleChange} />
                    </Form.Group>
                    <Button variant="primary" type="submit" style={{ fontWeight: '550', minWidth: '100px' }}>
                      Submit
                    </Button>
                  </Form>
                </motion.div>
              )}
            </AnimatePresence>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InsuranceContact;
