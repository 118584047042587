import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './case.css';

const BlogPost = () => {
    return (
        <>
            {/* Jumbotron */}
            <Container fluid className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5">
                <div>
                    <h1>Boost Your Business Growth Organically</h1>
                    <p>Learn more about whats going on with DSS</p>
                </div>
            </Container>

            {/* Blog Post Details */}
            <Container className="case-study-section">
                <Row>
                    <Col md={8}>
                        <div className="case-study-header">
                            <h2>Grow Your Business Organically</h2>
                            <p className="blog-text1">Web Development | SEO | Digital Marketing | 2024</p>
                        </div>

                        <div className="case-study-content">
                            <h3>Why Organic Growth Matters for Your Business</h3>
                            <p>In today's competitive digital landscape, growing your business organically is essential for long-term success. By leveraging effective SEO strategies, engaging websites, positive reviews, and targeted campaigns, you can build a strong online presence and attract more customers without relying on expensive paid advertising. At Divine Software Systems LLC, we specialize in helping businesses achieve sustainable growth through innovative web solutions and expert digital marketing.</p>

                            <h3>1. Enhance Your Online Presence with a Professional Website</h3>
                            <p>A well-designed website is the cornerstone of your digital strategy. At Divine Software Systems LLC, we create custom websites that reflect your brand, engage visitors, and drive conversions. Our responsive designs ensure your site looks great on all devices, providing a seamless user experience.</p>
                            <p>Our websites are optimized for search engines, incorporating relevant keywords, meta tags, and high-quality content. This optimization helps improve your search engine rankings, making it easier for potential customers to find you online and increasing your organic traffic.</p>

                            <h3>2. Leverage Customer Reviews to Build Trust and Credibility</h3>
                            <p>Positive customer reviews are powerful tools for building trust and credibility. Encourage your satisfied customers to leave reviews on platforms like Google, Yelp, and social media. Responding to reviews, both positive and negative, demonstrates that you value customer feedback and are committed to improving your services.</p>
                            <p>We can help you implement a review management strategy to monitor and respond to reviews effectively. This enhances your online reputation, builds trust with potential customers, and ultimately attracts more business.</p>

                            <h3>3. Engage with Targeted Digital Marketing Campaigns</h3>
                            <p>Effective digital marketing campaigns are key to reaching your target audience. From social media marketing to email campaigns, we create tailored strategies that resonate with your potential customers. Our campaigns are designed to drive engagement, generate leads, and convert prospects into loyal customers.</p>
                            <p>Using data-driven insights, we optimize your campaigns for maximum impact, ensuring you get the best return on investment. Our expertise in SEO and digital marketing ensures your business stays ahead of the competition.</p>

                            <h3>4. Go Fully Digital to Streamline Operations and Enhance Customer Experience</h3>
                            <p>Transitioning to a fully digital operation can significantly improve efficiency and customer satisfaction. From online booking systems to automated email responses, digital tools can streamline your business processes and provide a better experience for your clients.</p>
                            <p>We offer comprehensive digital transformation services to help you integrate the latest technologies into your business operations. This makes it easier to manage tasks, communicate with customers, and stay competitive in a rapidly evolving digital landscape.</p>

                            <h3>How Divine Software Systems LLC Can Help Transform Your Business</h3>
                            <p>At Divine Software Systems LLC, we specialize in helping businesses grow organically through innovative web solutions, effective SEO strategies, and targeted digital marketing campaigns. Our team of experts is dedicated to understanding your unique needs and delivering customized solutions that drive results.</p>
                            <p>Here's how we can help:</p>
                            <ul>
                                <li><b>Custom Web Development:</b> We create bespoke websites tailored to your business needs, ensuring a strong online presence that attracts and retains customers.</li>
                                <li><b>Search Engine Optimization (SEO):</b> Our SEO experts use the latest techniques to improve your website's visibility on search engines, driving more organic traffic to your site.</li>
                                <li><b>Review Management:</b> We help you manage and respond to customer reviews, enhancing your online reputation and building trust with potential clients.</li>
                                <li><b>Digital Marketing:</b> Our targeted marketing campaigns are designed to engage your audience, generate leads, and boost conversions.</li>
                                <li><b>Digital Transformation:</b> We assist you in adopting digital tools and technologies that streamline operations and improve customer experience.</li>
                            </ul>

                            <p>Ready to take your business to the next level? Contact Divine Software Systems LLC today to learn how we can help you achieve your growth goals. Let’s work together to bring your vision to life and make your business thrive in the digital landscape.</p>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="case-links-header">View Our Other Blog Posts</div>
                        <div className="case-links">
                        <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>2024 / Blog Post</Card.Title>
                                    <Card.Text>
                                        <a href='/case-study1'>Insurance Websites For Lead Generation</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>2024 / Blog Post</Card.Title>
                                    <Card.Text>
                                    <a href='/case-study2'>3rd Farmers Patis Cup Charity Tournament 
                                        </a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default BlogPost;
