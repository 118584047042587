/* global dataLayer */

// src/analytics.js

// Initialize Google Analytics and log page views
export const initGA = () => {
    // Initialize the Google Analytics tag
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-H31QZ6821D');
  }
  
  export const logPageView = (url) => {
    if (window.gtag) {
      window.gtag('config', 'G-H31QZ6821D', {
        'page_path': url,
      });
    }
  }
  