import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './divinefeatures.css'; // Ensure this path is correct for your CSS file

const DivineFeatures = () => {
    const features = [
        {
            title: "User-Friendly Interface",
            description: "Experience an intuitive interface designed for ease of use, enhancing navigation and task efficiency.",
            iconName: "bi-ui-radios-grid" // Represents interface layout
        },
        {
            title: "Secure Data Management",
            description: "Protect sensitive information with advanced encryption and secure server connections, ensuring data safety.",
            iconName: "bi-shield-lock" // Emphasizes security protection
        },
        {
            title: "Paperless Document Handling",
            description: "Reduce paper use with digital document storage, streamlining access and improving organizational efficiency.",
            iconName: "bi-file-earmark-text" // Represents documents
        },
        {
            title: "Real-Time Data Workflow",
            description: "Stay updated with real-time alerts and workflow monitoring to manage operations efficiently and effectively.",
            iconName: "bi-clock-history" // Symbolizes real-time updates
        },
        {
            title: "Customization Options",
            description: "Customize settings to fit operational needs, offering enhanced control and operational flexibility.",
            iconName: "bi-toggles2" // Reflects customization and adjustments
        }
    ];

    return (
        <div className="container" style={{ marginTop: '5rem', marginBottom: '5rem' }}>
            <div className="custom-services-subheader">
                <h3>Divine Messenger</h3>
                <span className="custom-line-icon"></span>
            </div>

            <div className="custom-wwo-section mt-3 mb-5">
                <h2>Top Five App Features</h2>
                <p className='insurance-feat'>Discover the key features that make Divine Messenger the perfect solution for enhancing efficiency, security, and customization in your business operations.</p>
            </div>

            <Row xs={1} md={2} lg={5} className="g-4">
                {features.map((feature, idx) => (
                    <Col key={idx}>
                        <Card className="divine-feature-card">
                            <Card.Body>
                                <i className={`bi ${feature.iconName} divine-feature-icon mb-2`} aria-hidden="true"></i>
                                <Card.Title className='about-feature-cards'>{feature.title}</Card.Title>
                                <Card.Text>{feature.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default DivineFeatures;
