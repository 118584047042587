import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DivineFeatures from '../divine-features/divinefeatures';
import DivineSections from '../divine-sections/divine-sections';
import DivineContact from '../divine-contact/divine-contact';
import { motion, useInView, useAnimation } from 'framer-motion';
import { Container } from 'react-bootstrap'; // Added Container import
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import './divinemessenger.css'; // Assuming you have CSS for styling

gsap.registerPlugin(ScrollTrigger);

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.4,
    },
  },
};

const wordVariants = {
  hidden: (direction) => ({
    opacity: 0,
    x: direction === 'left' ? -60 : 60,
  }),
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 70,
      damping: 10,
    },
  },
};

const iconVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1, y: 0,
    transition: {
      delay: 1.3,
      type: 'spring',
      stiffness: 70,
      damping: 10,
    },
  },
};

const Header = ({ navigateToWebPricing }) => {
  const component = useRef();
  const scrollDownRef = useRef();
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to('.icon-animation', {
        y: -10,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
        duration: 1,
      });

      
      
    }, component);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={component}>
      <section className="header-section about-container">
        <Container className="text-center">
          <motion.div
            className="text-container"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <motion.div className="word" custom="left" variants={wordVariants}>
              <span>
                <span className="highlight">D</span>IVINE
              </span>
            </motion.div>
            <motion.div className="word" custom="right" variants={wordVariants}>
              <span>
                ME<span className="highlight">SS</span>ENGER
              </span>
            </motion.div>
            <motion.div className="word" custom="left" variants={wordVariants}>
              <span>APPLICATION</span>
            </motion.div>
          </motion.div>
          <motion.div
            className="learn-more"
            initial="hidden"
            animate="visible"
            variants={iconVariants}
            ref={scrollDownRef}
          >
            <div className="circle-icon icon-animation">
              <FontAwesomeIcon icon={faArrowDown} size="lg" />
            </div>
            <span className="who-we">LEARN MORE</span>
          </motion.div>
        </Container>
      </section>
    </div>
  );
};

function DivineMessengerMobileApp() {
  const navigate = useNavigate();
  const navigateToWebPricing = () => {
    navigate('/divine-messenger'); // Adjust the navigation route as needed
  };

  return (
    <>
      <Header navigateToWebPricing={navigateToWebPricing} />
      <DivineFeatures />
      <DivineSections />
      <DivineContact />
    </>
  );
}

export default DivineMessengerMobileApp;
