import React, { useState, useEffect, useMemo } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faMobileAlt, faLaptopCode, faCogs, faUsers, faShieldAlt, faProjectDiagram, faSyncAlt, faDatabase } from '@fortawesome/free-solid-svg-icons';
import './divine.css'; // Ensure this path is correct for your styling
import ContactForm from './ContactForm'; // Import the ContactForm component

const Divineprice = () => {
  const initialFormData = useMemo(() => ({
    name: '',
    email: '',
    phone: '',
    message: '',
    pricingPlanTitle: '', // Added field for the selected pricing plan title
  }), []);

  const [formData, setFormData] = useState(initialFormData);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [selectedPlan, setSelectedPlan] = useState({
    title: '',
    price: '',
    features: [],
  }); // State to store the selected plan with default values
  const [showSnackbar, setShowSnackbar] = useState(false); // State for showing snackbar

  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    const mainContent = document.getElementById('main-content');
    
    if (showModal) {
      document.body.style.overflow = 'hidden';
      if (mainContent) mainContent.classList.add('blur-background');
      if (navbar) navbar.classList.add('blur');
    } else {
      document.body.style.overflow = '';
      if (mainContent) mainContent.classList.remove('blur-background');
      if (navbar) navbar.classList.remove('blur');
      setFormData(initialFormData); // Reset form data when modal is closed
    }
  }, [showModal, initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/xjvnjobd', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
      if (response.ok) {
        setShowSnackbar(true);
        setTimeout(() => setShowSnackbar(false), 5000);
        setShowModal(false);
        console.log(formData);
        console.log(selectedPlan.title);
      } else {
        alert('Oops! There was a problem with your submission.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending message.');
    }
  };

  const pricingPlans = [
    {
      title: "Basic Plan",
      price: "$4.50/Case",
      features: [
        "Device compatibility",
        "Digital document conversion",
        "Hands-on training and support",
        "Per call rate for small-scale operations",
        "Support via call, email, chat",
        "Custom Database",
      ],
    },
    {
      title: "Pro Plan",
      price: "$500/Month",
      features: [
        "Device compatibility",
        "Digital document conversion",
        "Hands-on training and support",
        "Flat rate for large-scale operations",
        "Support via call, email, chat",
        "Custom Database",
      ],
    },
    {
      title: "Enterprise Plan",
      price: "Website Bundle",
      features: [
        "Comprehensive feature set",
        "Priority support and tailored training",
        "Website bundle with premium features",
        "Seamless integration",
        "High-level security",
        "Websites designed for growth",
      ],
    },
  ];

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setFormData({ ...formData, pricingPlanTitle: plan.title }); // Update form data with selected plan title
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5, // Speed up the animation duration
        ease: "easeInOut",
      },
    },
  };

  return (
    <div>
      <div id="main-content">
        <Container fluid className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5" style={{ backgroundColor: 'black' }}>
          <div>
            <h1>Divine Messenger</h1>
            <p>Enhance your business with our Mobile App.</p>
          </div>
        </Container>

        {/* Pricing Cards */}
        <Container className="my-5">
          <h1 className="text-center pricing-table-title">SELECT A PLAN</h1>
          <Row>
            {pricingPlans.map((plan, index) => (
              <Col md={12} lg={4}  key={index}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={cardVariants}
                >
                  <Card className={`pricing-card ${index === 1 ? 'pricing-card-highlighted' : ''}`}>
                    <Card.Body>
                      <FontAwesomeIcon icon={index === 0 ? faMobileAlt : index === 1 ? faLaptopCode : faProjectDiagram} className="pricing-plan-icon" />
                      <p className="pricing-plan-title">{plan.title}</p>
                      <h3 className="pricing-plan-cost ml-auto">{plan.price}</h3>
                      <Button onClick={() => handlePlanSelect(plan)} className="pricing-plan-purchase-btn">Select Plan</Button>
                      <Container className="pricing-plan-features-container">
                        <ul className="pricing-plan-features">
                          {plan.features.map((feature, idx) => (
                            <li key={idx} className="pricing-plan-feature-item">
                              <FontAwesomeIcon icon={faCheckCircle} className="text-primary icon-pricing" /> {feature}
                            </li>
                          ))}
                        </ul>
                      </Container>
                    </Card.Body>
                  </Card>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>

        {/* Features Section */}
        <Container className="mt-5 p-5 bg-light border rounded pricing-con">
          <Row className="justify-content-center">
            <h3 className="what-you text-center mb-4">What You Get with Divine Messenger</h3>
          </Row>
          <Row className="feature-list">
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3, ease: "easeInOut" }} // Speed up hover animation duration
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faUsers} size="3x" className="mb-3" />
                <h4>User-Friendly Interface</h4>
                <p>Experience an intuitive interface designed for ease of use, enhancing navigation and task efficiency. Our user-friendly design ensures that even non-technical users can easily navigate and utilize all the features of the application, improving overall productivity and satisfaction.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3, ease: "easeInOut" }} // Speed up hover animation duration
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faShieldAlt} size="3x" className="mb-3" />
                <h4>Secure Data Management</h4>
                <p>Protect sensitive information with advanced encryption and secure server connections, ensuring data safety. Our robust security protocols are designed to safeguard your data from unauthorized access, breaches, and other security threats, providing you with peace of mind.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3, ease: "easeInOut" }} // Speed up hover animation duration
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faLaptopCode} size="3x" className="mb-3" />
                <h4>Paperless Document Handling</h4>
                <p>Reduce paper use with digital document storage, streamlining access and improving organizational efficiency. Our system allows you to easily store, retrieve, and manage documents electronically, reducing clutter and the risk of lost or misplaced papers.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3, ease: "easeInOut" }} // Speed up hover animation duration
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faSyncAlt} size="3x" className="mb-3" />
                <h4>Real-Time Workflow</h4>
                <p>Stay updated with real-time alerts and workflow monitoring to manage operations efficiently and effectively. Our real-time tracking features provide instant updates and notifications, allowing you to respond promptly to any changes or issues that arise.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3, ease: "easeInOut" }} // Speed up hover animation duration
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faCogs} size="3x" className="mb-3" />
                <h4>Customization Options</h4>
                <p>Customize settings to fit operational needs, offering enhanced control and operational flexibility. Our application can be tailored to suit the unique requirements of your business, allowing you to modify features and settings to better align with your workflows and objectives.</p>
              </motion.div>
            </Col>
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3, ease: "easeInOut" }} // Speed up hover animation duration
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faDatabase} size="3x" className="mb-3" />
                <h4>Data Management</h4>
                <p>Efficiently manage and analyze data to make informed business decisions. Our comprehensive data management solutions ensure that your data is organized, accessible, and actionable. With powerful tools for data collection, storage, and analysis, you can gain valuable insights and drive better outcomes for your business.</p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Contact Form Modal */}
      <ContactForm
        show={showModal}
        handleClose={handleClose}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        selectedPlan={selectedPlan}
      />

      {/* Snackbar for submission message */}
      {showSnackbar && (
        <div className="snackbar">
          Submission successful!
        </div>
      )}
    </div>
  );
};

export default Divineprice;
