import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './case.css';

const CaseStudy = () => {
    return (
        <>
            {/* Jumbotron */}
            <Container fluid className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5">
                <div>
                    <h1>Insurance Websites to Boost Lead Generations</h1>
                    <p>Learn more about what's going on with DSS</p>
                </div>
            </Container>

            {/* Case Study Details */}
            <Container className="case-study-section">
                <Row>
                    <Col md={8}>
                        <div className="case-study-header">
                            <h2>Insurance Websites</h2>
                            <p className="blog-text1">Insurance Websites | Year: 2024 | Service: Lead Generation</p>
                        </div>
                        
                        <div className="case-study-content">
                            <h3>Streamline Your Insurance Business with Customizable Websites</h3>

                            <h3>Why Choose Our Customizable Websites?</h3>

                            <p><b>1. Trusted Partnerships:</b> We collaborate with top insurance agencies such as Farmers Insurance, State Farm, and Allstate, as well as independent agents. Our solutions are trusted by industry leaders and tailored to meet the high standards of these partnerships.</p>

                            <p><b>2. Customizable Multi-Page Websites:</b></p>
                            <ul>
                                <li><b>Comprehensive Pages:</b> Our websites feature essential pages such as a Service Page, About Page, and Contact Page to provide a full overview of your business.</li>
                                <li><b>Expandable Layout:</b> Easily add more pages as your business grows, with options to include blog sections, FAQ pages, and more.</li>
                            </ul>

                            <p><b>3. Simplified Process:</b></p>
                            <ul>
                                <li><b>Efficient Quote Form:</b> Our quote forms are designed to be completed in under a minute, simplifying the process for your clients.</li>
                                <li><b>Client-Friendly Design:</b> The user-friendly layout ensures ease of use, allowing clients to navigate through multiple pages seamlessly.</li>
                            </ul>

                            <h3>Available Quote Types</h3>

                            <p>Our websites support various types of insurance quotes, including:</p>
                            <ul>
                                <li>Auto Insurance</li>
                                <li>Home Insurance</li>
                                <li>Commercial Insurance</li>
                                <li>Life Insurance</li>
                                <li>Specialty Insurance</li>
                            </ul>

                            <p>Additional quoting forms can be added as needed, providing the flexibility to meet all your clients' insurance needs.</p>

                            <h3>Affordable Pricing</h3>

                            <p>We offer competitive pricing to ensure our solutions are accessible to all insurance agents:</p>
                            <ul>
                                <li><b>No Startup Fee</b></li>
                                <li><b>Monthly Maintenance:</b> $150</li>
                                <li><b>Referral Discounts:</b></li>
                                <ul>
                                    <li>Refer a friend, and get your first month free!</li>
                                </ul>
                            </ul>

                            <h3>How It Works</h3>

                            <p>Our customizable multi-page websites are designed to enhance lead generation and client engagement:</p>
                            <ul>
                                <li><b>Post the Link Anywhere:</b> Share your website link on social media, email campaigns, or business cards to reach more potential clients.</li>
                                <li><b>Instant Notifications:</b> When a client fills out the quote form, you receive an immediate notification, allowing for quick follow-up.</li>
                                <li><b>Increased Lead Generation:</b> By enabling agents to work directly with leads or potential prospects, our websites maximize your earning potential.</li>
                            </ul>

                            <h3>Real-World Success</h3>

                            <p>One of our clients, Patrick Rodriguez, a Farmers Insurance agent in Fremont, CA, has seen significant benefits from using our customizable website. His site showcases the efficiency and customization options available through our solutions.</p>

                            <h3>Transform Your Insurance Business</h3>

                            <p>At Divine Software Systems LLC, we are committed to helping insurance agents succeed by providing top-notch web solutions. Our customizable multi-page websites are designed to streamline your quoting process, enhance client satisfaction, and boost lead generation.</p>

                            <p>Ready to transform your insurance business? Contact us today to learn more about how our multi-page websites can benefit you. Let’s work together to bring your vision to life!</p>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="case-links-header">View Our Other Blog Posts</div>
                        <div className="case-links">
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>2024 / Blog Post</Card.Title>
                                    <Card.Text>
                                        <a href='/case-study3'>Boost Your Business Growth Organically</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>2024 / Blog Post</Card.Title>
                                    <Card.Text>
                                        <a href='/case-study2'>3rd Farmers Patis Cup Charity Tournament</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CaseStudy;
