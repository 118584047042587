import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const TermService = () => {
    return (
        <>
          <Container fluid className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5" style={{ backgroundColor: 'black' }}>
          <div> {/* Wrapper div for the text */}
            <h1>Terms of Service</h1>
            <p>Your trusted partner in digital transformation.</p>
          </div>
        </Container>
    
          <Container className="privacy-details mt-4" style={{ textAlign: 'left' }}>  
            <Row className="justify-content-center">
              <Col xs={12} md={8}>
              <h1>Terms of Service</h1>
<p>Last updated: July 19, 2024</p>
<p>Welcome to Divine Software Systems, an established LLC based in Fremont, CA, operating under the DBA name DSS LLC. This comprehensive Terms of Service document covers various aspects of our mobile application and website development services, including user accounts, intellectual property rights, feedback usage, promotions, liability disclaimers, and contact information.</p>
<p>Please read these Terms of Service ("Terms," "Terms of Service") carefully before using the Divine Software Systems mobile application, website, or any related services (collectively referred to as the "Service") operated by DSS LLC, located at 37675 Niles Blvd. Fremont, CA 94536.</p>

<h2>1. Acceptance of Terms</h2>
<p>By accessing or using the Service, you agree to be bound by these Terms and all applicable laws and regulations. If you do not agree with any part of these Terms, you may not access the Service. These Terms apply to all visitors, users, and others who access or use the Service.</p>

<h2>2. Account Creation</h2>
<p>To access certain features of the Service, you may be required to create an account. By creating an account, you agree to provide accurate, current, and complete information and to maintain the confidentiality of your account credentials. You are responsible for all activities that occur under your account.</p>
<p>Account registration requires you to provide your name, email address, and other information. You must update any information provided at registration to keep it accurate, current, and complete.</p>

<h2>3. Subscription Plans</h2>
<p>The Service may offer subscription plans for accessing premium features or content. By subscribing, you agree to pay the applicable fees and abide by the terms and conditions of the subscription. Subscription fees are non-refundable except as required by law.</p>
<p>We reserve the right to change subscription fees at any time. We will notify you of any fee changes in advance. Continued use of the Service after the fee change constitutes your agreement to the new fees.</p>

<h2>4. Intellectual Property</h2>
<p>All content, including but not limited to logos, visual designs, trademarks, and other intellectual property associated with the Service, is the exclusive property of Divine Software Systems. Unauthorized use, reproduction, or distribution of any content from the Service is prohibited.</p>
<p>Users are granted a limited, non-exclusive, and revocable license to access and use the Service for personal, non-commercial purposes. This license is subject to compliance with these Terms.</p>

<h2>5. Feedback</h2>
<p>We welcome feedback and suggestions from users regarding the Service. By providing feedback, you grant Divine Software Systems the right to use, modify, and incorporate such feedback into the Service without any obligation to compensate you or provide credit.</p>
<p>Feedback may include but is not limited to comments, suggestions, or ideas for improving the Service. We may use this feedback in any manner without restriction or compensation to you.</p>

<h2>6. Promotions, Contests, and Sweepstakes</h2>
<p>Divine Software Systems may offer promotions, contests, or sweepstakes through the Service. Participation in such activities is subject to additional terms and conditions, which will be made available at the time of participation.</p>
<p>Promotions, contests, and sweepstakes may require you to provide personal information. By participating, you consent to the collection and use of your information for the administration of the activity.</p>

<h2>7. User-Generated Content</h2>
<p>Users may create and upload content to the Service. By doing so, you represent and warrant that you have the necessary rights and permissions to do so. Divine Software Systems reserves the right to remove any user-generated content that violates these Terms or is deemed inappropriate.</p>
<p>User-generated content must not infringe on the rights of others or violate any applicable laws. We reserve the right to review and remove any content that we deem inappropriate or that violates these Terms.</p>

<h2>8. Privacy Policy</h2>
<p>Our Privacy Policy describes how we collect, use, and disclose information about users of the Service. By using the Service, you consent to the collection and use of your information as described in our Privacy Policy.</p>
<p>The Privacy Policy is incorporated into these Terms by reference. Please review the Privacy Policy carefully before using the Service.</p>

<h2>9. Liability Disclaimer</h2>
<p>The Service is provided on an "as is" and "as available" basis. We do not warrant that the Service will be uninterrupted, error-free, or secure. You use the Service at your own risk. We disclaim any liability for damages arising from your use of the Service or any content therein. Divine Software Systems, DSS LLC, and its affiliates shall not be held liable for any legal issues, damages, or losses resulting from the use of the Service or any related activities.</p>
<p>We do not guarantee the accuracy or completeness of any information provided through the Service. We are not responsible for any errors or omissions in the content or for any loss or damage that may result from your reliance on the content.</p>

<h2>10. Indemnity</h2>
<p>You agree to indemnify, defend, and hold harmless Divine Software Systems, DSS LLC, and its affiliates, officers, directors, employees, agents, licensors, and suppliers from and against all claims, losses, liabilities, expenses, damages, and costs, including reasonable attorneys' fees, arising out of or in connection with your use of the Service, violation of these Terms, or infringement of any third-party rights.</p>
<p>This indemnification obligation will survive the termination of your account or the Service.</p>

<h2>11. Third-Party Links</h2>
<p>The Service may contain links to third-party websites or services that are not owned or controlled by Divine Software Systems. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that Divine Software Systems shall not be responsible or liable for any damage or loss caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</p>
<p>We recommend that you read the terms and conditions and privacy policies of any third-party websites or services that you visit.</p>

<h2>12. Governing Law</h2>
<p>These Terms shall be governed and construed in accordance with the laws of the State of California, without regard to its conflict of law provisions. Any dispute arising out of or relating to these Terms or the Service will be resolved exclusively in the state or federal courts located in Alameda County, California.</p>
<p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.</p>

<h2>13. Termination</h2>
<p>We may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms. Upon termination, your right to use the Service will immediately cease.</p>
<p>If you wish to terminate your account, you may simply discontinue using the Service. All provisions of these Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>

<h2>14. Changes to Terms</h2>
<p>We reserve the right to update or modify these Terms of Service at any time without prior notice. Any changes will be effective immediately upon posting the updated Terms on this page. Your continued use of the Service after any modifications to the Terms constitutes your acceptance of the changes.</p>
<p>We encourage you to review these Terms periodically to stay informed about our updates. If you do not agree to the modified Terms, you must stop using the Service.</p>

<h2>15. Contact Information</h2>
<p>If you have any questions or concerns about these Terms of Service, please contact us:</p>
<ul>
<li>By email: <a href="mailto:divinesoftwaresystems@gmail.com">divinesoftwaresystems@gmail.com</a></li>
<li>By visiting our website: <a href="https://www.divinesoftwaresystems.com/">https://www.divinesoftwaresystems.com/</a></li>
<li>By phone: +1 (310) 503-3805</li>
</ul>

                {/* Additional sections can continue with the same pattern */}
              </Col>
            </Row>
          </Container>
        </>
      );
    }
    

export default TermService;
