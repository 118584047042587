import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Accordion, Form, Button } from 'react-bootstrap';
import { motion, useInView, AnimatePresence, useAnimation } from 'framer-motion';
import './graphic.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt, faArrowDown, faPlusCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import customImage1 from './12.webp';
import customImage2 from './13.webp';
import customImage3 from './14.webp';
import customImage4 from './15.webp';
import customImage5 from './16.webp';
import customImage6 from './17.webp';
import customImage7 from './15.webp';
import customImage8 from './13.webp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import droneVideo1 from './droneVideo1.mp4';
import droneVideo2 from './droneVideo2.mp4';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.4,
    },
  },
};

const wordVariants = {
  hidden: (direction) => ({
    opacity: 0,
    x: direction === 'left' ? -60 : 60,
  }),
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 70,
      damping: 10,
    },
  },
};

const iconVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.3,
      type: 'spring',
      stiffness: 70,
      damping: 10,
    },
  },
};

const Header = ({ navigateToGraphicPricing }) => {
  const component = useRef();
  const scrollDownRef = useRef();
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to('.icon-animation', {
        y: -10,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
        duration: 1,
      });

    }, component);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={component}>
      <section className="header-section about-container">
        <Container className="text-center">
          <motion.div
            className="text-container"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <motion.div className="word" custom="left" variants={wordVariants}>
              <span className='highlight'>D</span>IGITAL
            </motion.div>
            <motion.div className="word" custom="right" variants={wordVariants}>
                MARKETING
            </motion.div>
            <motion.div className="word" custom="left" variants={wordVariants}>
              <span>
                <span className='highlight'>S</span>ERVICE<span className='highlight'>S</span>
              </span>
            </motion.div>
          </motion.div>
          <motion.div
            className="learn-more"
            initial="hidden"
            animate="visible"
            variants={iconVariants}
            ref={scrollDownRef}
          >
            <div className="circle-icon icon-animation">
              <FontAwesomeIcon icon={faArrowDown} size="lg" />
            </div>
            <span className="who-we">LEARN MORE</span>
          </motion.div>
        </Container>
      </section>
    </div>
  );
};

const GraphicDesign = () => {
  const navigate = useNavigate();
  const navigateToGraphicPricing = () => navigate('/graphic-price');

  const images = [
    customImage1,
    customImage7,
    customImage3,
    customImage4,
    customImage2,
    customImage6,
    customImage5,
    customImage8,
  ];

  return (
    <>
      <Header navigateToGraphicPricing={navigateToGraphicPricing} />
      <Features />
      <InfiniteCarousel />
      <RecentWork images={images} />
      <FAQSection />
      <HomeContact />
    </>
  );
};

const Features = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  return (
    <section className="py-5" id="features">
      <Container className="my-5">
        <div className="custom-services-subheader">
          <h3>Key Features</h3>
          <span className="custom-line-icon"></span>
        </div>
        <div className="custom-wwo-section mt-3 mb-5 feature-accordian-1">
          <h2>DIGITAL MARKETING</h2>
        </div>
      </Container>

      <Container className="my-5">
        <motion.div
          className="py-5 scrollable-section"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.6, ease: "easeOut" }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <Accordion activeKey={activeKey} className="feature-accordion">
            {accordionItems.map((item, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header onClick={() => handleToggle(index.toString())}>
                  <span>{item.title}</span>
                  <div className="accordion-icon">
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="icon-circle"
                      style={{
                        transform: activeKey === index.toString() ? 'rotate(45deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                  </div>
                </Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <p>{item.description}</p>
                  <ul>
                    {item.points.map((point, i) => (
                      <li key={i}>{point}</li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </motion.div>
      </Container>
    </section>
  );
};

const accordionItems = [
  {
    title: 'Social Media Management',
    description: 'Engaging your audience and growing your brand through social platforms:',
    points: [
      'Regular content creation and posting across major platforms.',
      'Audience engagement through comments, messages, and updates.',
      'Analytics-driven insights to improve performance.',
    ],
  },
  {
    title: 'SEO Optimization',
    description: 'Improving your website’s search engine ranking and visibility:',
    points: [
      'Keyword research and on-page optimization.',
      'Content strategy focused on increasing organic traffic.',
      'Technical SEO and performance enhancements.',
    ],
  },
  {
    title: 'Email Marketing Campaigns',
    description: 'Reaching your audience through targeted email newsletters:',
    points: [
      'Personalized email campaigns to boost engagement.',
      'Automated workflows for timely messaging.',
      'Detailed analytics to track open rates and conversions.',
    ],
  },
  {
    title: 'Content Creation',
    description: 'Developing tailored content that resonates with your audience:',
    points: [
      'Custom blog posts optimized for SEO.',
      'Graphics, infographics, and social media content.',
      'Consistent brand messaging across all platforms.',
    ],
  },
  {
    title: 'Brand Identity Development',
    description: 'Building a cohesive and impactful brand identity:',
    points: [
      'Logo, color palette, typography, and brand guidelines.',
      'Ensuring consistency across all brand touchpoints.',
      'Enhancing brand recognition and trust.',
    ],
  },
  {
    title: 'Custom Video Production',
    description: 'Producing high-quality videos for digital marketing campaigns:',
    points: [
      'Concept development, scripting, and video production.',
      'Professional editing, motion graphics, and animation.',
      'Final delivery optimized for multiple platforms.',
    ],
  },
];


const InfiniteCarousel = () => {
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const [isPlaying1, setIsPlaying1] = useState(false);
  const [isPlaying2, setIsPlaying2] = useState(false);

  const handlePlay1 = () => {
    videoRef1.current.play();
    setIsPlaying1(true);
  };

  const handlePlay2 = () => {
    videoRef2.current.play();
    setIsPlaying2(true);
  };

  const handlePause1 = () => {
    videoRef1.current.pause();
    setIsPlaying1(false);
  };

  const handlePause2 = () => {
    videoRef2.current.pause();
    setIsPlaying2(false);
  };

  const handleEnded1 = () => {
    setIsPlaying1(false);
  };

  const handleEnded2 = () => {
    setIsPlaying2(false);
  };

  return (
    <section className="carousel-section">
      <div className="carousel-background-text">
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
      </div>
      <div className="tech-can">
        <div className="carousel-header">
          <h2>Custom Drone Footage</h2>
          <p>We offer innovative services based on your business needs:</p>
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col md={6} className="d-flex justify-content-center">
              <div className={`video-container ${isPlaying1 ? 'playing' : ''}`} onClick={isPlaying1 ? handlePause1 : handlePlay1}>
                <video
                  ref={videoRef1}
                  src={droneVideo1}
                  className="video"
                  onEnded={handleEnded1}
                  style={{ cursor: 'pointer' }}
                />
                <div className={`overlay ${isPlaying1 ? 'hidden' : ''}`} />
                <div className={`location-text ${isPlaying1 ? 'visible' : ''}`}>Long Beach, CA</div>
                {!isPlaying1 && (
                  <Button 
                    className="custom-play-button" 
                    onClick={handlePlay1}
                  >
                    <FontAwesomeIcon icon={faPlayCircle} size="4x" />
                  </Button>
                )}
              </div>
            </Col>
            <Col md={6} className="d-flex justify-content-center">
              <div className={`video-container ${isPlaying2 ? 'playing' : ''}`} onClick={isPlaying2 ? handlePause2 : handlePlay2}>
                <video
                  ref={videoRef2}
                  src={droneVideo2}
                  className="video"
                  onEnded={handleEnded2}
                  style={{ cursor: 'pointer' }}
                />
                <div className={`overlay ${isPlaying2 ? 'hidden' : ''}`} />
                <div className={`location-text ${isPlaying2 ? 'visible' : ''}`}>San Francisco, CA</div>
                {!isPlaying2 && (
                  <Button 
                    className="custom-play-button" 
                    onClick={handlePlay2}
                  >
                    <FontAwesomeIcon icon={faPlayCircle} size="4x" />
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

const RecentWork = ({ images }) => {
  const containerRefTop = useRef(null);
  const containerRefBottom = useRef(null);
  const isInViewTop = useInView(containerRefTop, { once: true });
  const isInViewBottom = useInView(containerRefBottom, { once: true });
  const lastScrollY = useRef(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isMobile) return;

      const currentScrollY = window.scrollY;

      if (containerRefTop.current && containerRefBottom.current) {
        if (currentScrollY > lastScrollY.current) {
          containerRefTop.current.scrollLeft += 2;
          containerRefBottom.current.scrollLeft -= 2;
        } else {
          containerRefTop.current.scrollLeft -= 2;
          containerRefBottom.current.scrollLeft += 2;
        }
      }
      lastScrollY.current = currentScrollY;
    };

    if (isInViewTop || isInViewBottom) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isInViewTop, isInViewBottom, isMobile]);

  useEffect(() => {
    if (containerRefBottom.current) {
      containerRefBottom.current.scrollLeft = containerRefBottom.current.scrollWidth; // Start at the far right
    }
  }, []);

  return (
    <section className="py-5 work-bg scrollable-section">
      <div className="full-width">
        <div className="p-5 rounded-3">
          <div className="custom-services-subheader">
            <h1 className="display-4 explore-head">Explore Our Recent Projects</h1>
            <p>TAKE A LOOK AT SOME OF THE AMAZING WORK WE'VE DONE FOR OUR CLIENTS. WE PRIDE OURSELVES ON DELIVERING HIGH-QUALITY PROJECTS THAT DRIVE RESULTS.</p>
          </div>
          <div className="image-gallery-container" ref={containerRefTop}>
            {images.map((src, index) => (
              <div key={index} className="image-container">
                <img src={src} alt={`Graphic design project ${index}`} className="gallery-image" />
              </div>
            ))}
          </div>
          <div className="image-gallery-container mt-2" ref={containerRefBottom}>
            {images.map((src, index) => (
              <div key={index} className="image-container">
                <img src={src} alt={`Graphic design project ${index}`} className="gallery-image" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const FAQSection = () => (
  <motion.section 
    className="py-5 faq-dark-bg" 
    id="faq" 
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.2, duration: 0.6, ease: "easeOut" }}
    viewport={{ once: true, amount: 0.3 }}
  >
    <div className="container px-5">
      <div className="custom-services-subheader">
        <h3>FAQ Section</h3>
        <span className="custom-line-icon"></span>
      </div>
      <div className="custom-wwo-section mt-3 faq-section-1">
        <h2>Frequently Asked Questions</h2>
      </div>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingOne">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                    aria-expanded="false" aria-controls="collapseOne">
              What digital marketing services do you offer?
            </button>
          </h3>
          <div id="collapseOne" className="accordion-collapse collapse"
               aria-labelledby="headingOne">
            <div className="accordion-body">
              We offer a wide range of digital marketing services including social media management, SEO, email marketing, and content creation tailored to your brand’s needs.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingtwo">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsetwo"
                    aria-expanded="false" aria-controls="collapsetwo">
              How do you improve search engine rankings with SEO?
            </button>
          </h3>
          <div id="collapsetwo" className="accordion-collapse collapse"
               aria-labelledby="headingtwo">
            <div className="accordion-body">
              We focus on keyword research, on-page optimization, content strategy, and technical SEO improvements to enhance your website’s visibility and ranking on search engines.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingthree">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsethree"
                    aria-expanded="false" aria-controls="collapsethree">
              How do you manage social media for businesses?
            </button>
          </h3>
          <div id="collapsethree" className="accordion-collapse collapse"
               aria-labelledby="headingthree">
            <div className="accordion-body">
              We handle content creation, regular posting, audience engagement, and performance analysis across major social platforms to help grow your brand online.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingfour">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsefour"
                    aria-expanded="false" aria-controls="collapsefour">
              Do you offer email marketing services?
            </button>
          </h3>
          <div id="collapsefour" className="accordion-collapse collapse"
               aria-labelledby="headingfour">
            <div className="accordion-body">
              Yes, we create personalized email marketing campaigns to engage your audience, drive conversions, and provide detailed analytics on campaign performance.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingfive">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsefive"
                    aria-expanded="false" aria-controls="collapsefive">
              How can I get a quote for digital marketing services?
            </button>
          </h3>
          <div id="collapsefive" className="accordion-collapse collapse"
               aria-labelledby="headingfive">
            <div className="accordion-body">
            To receive a customized quote, visit the 'Digital Marketing' section on our website or contact us directly through our Contact Us page. We're ready to help elevate your brand's online presence.
            </div>
          </div>
        </div>
      </div>
    </div>
  </motion.section>
);




const HomeContact = () => {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleSubmitVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const formAppearVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
        duration: 0.5,
        ease: 'easeInOut'
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    try {
      const response = await fetch('https://formspree.io/f/xjvnnjar', {
        method: 'POST',
        body: new FormData(form),
        headers: {
          'Accept': 'application/json'
        },
      });

      const data = await response.json();
      if (data.ok) {
        setSubmitted(true);
      } else {
        alert('Oops! There was a problem with your submission.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending message.');
    }
  };

  return (
    <Container fluid className="custom-container help-me">
      <Row className="justify-content-md-center">
        <Col sm={12} lg={6} xl={6} xxl={6} className="left-column">
          <h2 className="homeform-heading">Get in Touch</h2>
          <p className="contact-text1 small-text">
            We are a skilled team delivering custom websites, apps, graphic designs, and marketing solutions for your business.
          </p>
          <div className="contact-info">
            <p className="contact-detail-item">
              <span className="icon-circle"><FontAwesomeIcon icon={faPhone} className="contact-icon" /></span> 
              <span className="contact-detail">+1 (310)-503-3805</span>
            </p>
            <p className="contact-detail-item email-detail">
              <span className="icon-circle"><FontAwesomeIcon icon={faEnvelope} className="contact-icon" /></span> 
              <span className="contact-detail ">hello@divinesoftwaresystems.com</span>
            </p>
            <p className="contact-detail-item">
              <span className="icon-circle"><FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" /></span> 
              <span className="contact-detail">
                US Office: <a href="https://www.google.com/maps/place/Divine+Software+Systems+LLC/@37.5763866,-121.979915,17z/data=!3m1!4b1!4m6!3m5!1s0x808fc13cb689ed09:0x2abc072afed6bcb8!8m2!3d37.5763866!4d-121.9773401!16s%2Fg%2F11y5l0w8my?entry=ttu" style={{ textDecoration: 'underline', color: 'inherit' }}>Fremont, CA</a>
              </span>
            </p>
            <p className="contact-detail-item">
              <span className="icon-circle"><FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" /></span> 
              <span className="contact-detail">
                PH Office: <a href="https://www.google.com/maps/place/BM+ONE+office+and+commercial+building/@14.4916087,120.9937898,17z/data=!4m16!1m9!3m8!1s0x3397cff3448fe381:0xf4f092d112e2d74f!2sBM+ONE+office+and+commercial+building!8m2!3d14.4916041!4d120.9937636!9m1!1b1!16s%2Fg%2F11txlr0tdy!3m5!1s0x3397cff3448fe381:0xf4f092d112e2d74f!8m2!3d14.4916041!4d120.9937636!16s%2Fg%2F11txlr0tdy?entry=ttu" style={{ textDecoration: 'underline', color: 'inherit' }}>Manila, PH</a>
              </span>      
            </p>
          </div>
        </Col>
        <Col sm={12} lg={6} xl={6} xxl={6}>
          <AnimatePresence mode="wait">
            {submitted ? (
              <motion.div
                key="thank-you"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={handleSubmitVariants}
                className="paper-like-container text-center thank-you-message"
              >
                <h3 className="thank-you-title">Thank you for reaching out!</h3>
                <p className="thank-you-text">Your message has been successfully submitted.</p>
                <p className="thank-you-text">We appreciate your interest and will get back to you promptly.</p>
                <div className="email-response">
                  <h4 className="response-title">In the meantime, here's what to expect:</h4>
                  <ul className="response-list">
                    <li>Confirmation email has been sent to the provided address.</li>
                    <li>Your inquiry is being reviewed by our team of experts.</li>
                    <li>We will respond to your message within 24-48 hours.</li>
                  </ul>
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="form"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.3 }}
                variants={formAppearVariants}
                className="paper-like-container"
              >
                <h2 className="form-header">Make an Appointment</h2>
                <Form className="centered-form" onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control type="text" placeholder="Full Name" className="paper-like-input" name="name" value={formData.name} onChange={handleChange} />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="text" placeholder="Phone Number" className="paper-like-input" name="phone" value={formData.phone} onChange={handleChange} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" placeholder="Email Address" className="paper-like-input" name="email" value={formData.email} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={5} placeholder="Message" className="paper-like-input" name="message" value={formData.message} onChange={handleChange} />
                  </Form.Group>
                  <Button variant="primary" type="submit" style={{ fontWeight: '550', minWidth: '100px' }}>
                    Submit
                  </Button>
                </Form>
              </motion.div>
            )}
          </AnimatePresence>
        </Col>
      </Row>
    </Container>
  );
};

export default GraphicDesign;
