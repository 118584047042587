import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLaptop, faGolfBall, faSeedling } from '@fortawesome/free-solid-svg-icons';
import './clients.css';

const cases = [
  { id: 1, title: 'Insurance Websites', year: '2024', type: 'Blog Post', description: 'Learn how our customized websites streamline insurance quotes and boost leads, featuring a success story with Farmers Insurance.', link: '/case-study1', icon: faLaptop, hiddenDescription: 'Read more about our Insurance Websites case study.' },
  { id: 2, title: '3rd Annual Patis Cup', year: '2024', type: 'Blog Post', description: 'Discover the highlights of the 3rd Farmers Patis Cup Charity Golf Tournament, hosted by Patrick Rodriguez, featuring fun, networking, and community support.', link: '/case-study2', icon: faGolfBall, hiddenDescription: 'Explore the details of the 3rd Annual Patis Cup Charity Golf Tournament.' },
  { id: 3, title: 'Grow Organically', year: '2024', type: 'Blog Post', description: 'Learn how Divine Software Systems helps businesses grow with expert SEO, engaging websites, positive reviews, and targeted digital marketing campaigns.', link: '/case-study3', icon: faSeedling, hiddenDescription: 'Find out how we help businesses grow organically.' },
];

const itemVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};

const ClientsTabSection = () => {
  return (
    <Container className="process-section">
      <Row className="header-row text-center mb-4">
        <Col xs={12}>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={itemVariants}
            transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
          >
            <h2 className="main-header1">Our Ways To Success Blog</h2>
            <h4 className="sub-header1">
              From project milestones to strategic tips for growing your business, <br />
              delve into our recent news, events, and partnerships
            </h4>
          </motion.div>
        </Col>
      </Row>
      <Row className="justify-content-center mb-5">
        <Col xs={12} md={12} lg={12} className="mb-4">
          <div className="grid">
            {cases.map((caseStudy) => (
              <motion.div
                key={caseStudy.id}
                className="card-case"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={itemVariants}
                transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
              >
                <div className="image-circle">
                  <FontAwesomeIcon icon={caseStudy.icon} size="2x" className="icon" />
                </div>
                <small>{caseStudy.year} / {caseStudy.type}</small>
                <h4>{caseStudy.title}</h4>
                <p>{caseStudy.description}</p>
                <a href={caseStudy.link} className="learn-more-circle">
                  <span className="learn-more-text">Learn More</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span className="visually-hidden">{caseStudy.hiddenDescription}</span>
                </a>
              </motion.div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ClientsTabSection;
