import React, { useState, useEffect, useMemo } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import './graphic-price.css'; // Ensure this path is correct for your styling
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faCheckCircle, faVideo, faEnvelope, faSearch, faEdit, faChartLine } from '@fortawesome/free-solid-svg-icons';
import ContactForm from './ContactForm'; // Import the ContactForm component

const DigitalMarketingPricing = () => {
  const initialFormData = useMemo(() => ({
    name: '',
    phone: '',
    email: '',
    businessName: '',
    message: '',
    pricingPlanTitle: '', // Added field for the selected pricing plan title
  }), []);

  const [formData, setFormData] = useState(initialFormData);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [selectedPlan, setSelectedPlan] = useState({
    title: '',
    cost: '',
    features: [],
  }); // State to store the selected plan with default values
  const [showSnackbar, setShowSnackbar] = useState(false); // State for showing snackbar

  const handleResize = () => {
    // Removed unused state: setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    const mainContent = document.getElementById('main-content');
    
    if (showModal) {
      document.body.style.overflow = 'hidden';
      if (mainContent) mainContent.classList.add('blur-background');
      if (navbar) navbar.classList.add('blur');
    } else {
      document.body.style.overflow = '';
      if (mainContent) mainContent.classList.remove('blur-background');
      if (navbar) navbar.classList.remove('blur');
      setFormData(initialFormData); // Reset form data when modal is closed
    }
  }, [showModal, initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/xzbnnone', {
        method: 'POST',
        body: new FormData(e.target),
        headers: {
          'Accept': 'application/json',
        },
      });
      const data = await response.json();
      if (data.ok) {
        setShowSnackbar(true);
        setTimeout(() => setShowSnackbar(false), 5000);
        setShowModal(false);
        console.log(formData);
        console.log(selectedPlan.title);
      } else {
        alert('Oops! There was a problem with your submission.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending message.');
    }
  };

  const pricingPlans = [
    {
      title: "Basic Plan",
      cost: "$300 /Month",
      features: [
        "1 Short-Form Content Video per Month",
        "2 Original Blogs per Month on Website",
        "24 Social Media Posts per Month",
        "Monthly Email Newsletter",
        "Off-Site Content",
        "SEO Boosting",
        "Custom Blog Landing Page",
      ],
    },
    {
      title: "Professional Plan",
      cost: "$500 /Month",
      features: [
        "4 Short-Form Content Videos per Month",
        "4 Original Blogs per Month on Website",
        "32 Social Media Posts per Month",
        "Monthly Email Newsletter",
        "Off-Site Content",
        "SEO Boosting",
        "Custom Blog Landing Page",
      ],
    },
    {
      title: "Enterprise Plan",
      cost: "Custom Pricing Available",
      features: [
        "Customized Social Media and SEO Strategies",
        "Short-Form Content Videos",
        "Social Media Posts per Month",
        "Original Blogs per Month on Website",
        "Monthly Email Newsletter",
        "Off-Site Content",
        "SEO Boosting",
        "Custom Blog Landing Page",
      ],
    },
  ];

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setFormData({ ...formData, pricingPlanTitle: plan.title }); // Update form data with selected plan title
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div>
      <div id="main-content">
        <Container fluid className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5" style={{ backgroundColor: 'black' }}>
          <div>
            <h1>Digital Marketing</h1>
            <p>Boost your online presence with our custom digital marketing packages.</p>
          </div>
        </Container>

        {/* Pricing Cards */}
        <Container className="my-5">
          <h1 className="text-center pricing-table-title">SELECT A PLAN</h1>
          <Row>
            {pricingPlans.map((plan, index) => (
              <Col md={12} lg={4} key={index}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={cardVariants}
                >
                  <Card className={`pricing-card ${index === 1 ? 'pricing-card-highlighted' : ''}`}>
                    <Card.Body>
                      <FontAwesomeIcon icon={faBullhorn} className="pricing-plan-icon" />
                      <p className="pricing-plan-title">{plan.title}</p>
                      <h3 className="pricing-plan-cost ml-auto">{plan.cost}</h3>
                      <Button onClick={() => handlePlanSelect(plan)} className="pricing-plan-purchase-btn">Select Plan</Button>
                      <Container className="pricing-plan-features-container">
                        <ul className="pricing-plan-features">
                          {plan.features.map((feature, idx) => (
                            <li key={idx} className="pricing-plan-feature-item">
                              <FontAwesomeIcon icon={faCheckCircle} className="text-primary icon-pricing" /> {feature}
                            </li>
                          ))}
                        </ul>
                      </Container>
                    </Card.Body>
                  </Card>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>

       {/* Features Section */}
{/* Features Section */}
<Container className="mt-5 p-5 bg-light border rounded pricing-con">
  <Row className="justify-content-center">
    <h3 className="what-you text-center mb-4">What You Get with Our Digital Marketing Services</h3>
  </Row>
  <Row className="feature-list">
    <Col xs={12} md={6} className="mb-4">
      <motion.div
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        className="feature-card p-3 text-center"
      >
        <FontAwesomeIcon icon={faBullhorn} size="3x" className="mb-3" />
        <h4>Social Media Management</h4>
        <p>Engage your audience with regular, high-quality posts across your social media channels. We handle content creation, posting, and performance tracking.</p>
      </motion.div>
    </Col>
    <Col xs={12} md={6} className="mb-4">
      <motion.div
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        className="feature-card p-3 text-center"
      >
        <FontAwesomeIcon icon={faSearch} size="3x" className="mb-3" />
        <h4>SEO Boosting</h4>
        <p>Optimize your website’s visibility with targeted keywords and technical enhancements to improve search engine rankings and drive organic traffic.</p>
      </motion.div>
    </Col>
    <Col xs={12} md={6} className="mb-4">
      <motion.div
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        className="feature-card p-3 text-center"
      >
        <FontAwesomeIcon icon={faVideo} size="3x" className="mb-3" />
        <h4>Custom Videos</h4>
        <p>Create short-form videos to captivate your audience and boost engagement. We handle production from concept to delivery, tailored to your brand.</p>
      </motion.div>
    </Col>
    <Col xs={12} md={6} className="mb-4">
      <motion.div
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        className="feature-card p-3 text-center"
      >
        <FontAwesomeIcon icon={faEnvelope} size="3x" className="mb-3" />
        <h4>Email Marketing</h4>
        <p>Send personalized, engaging newsletters to your audience. We create, manage, and track email campaigns to maximize engagement and conversions.</p>
      </motion.div>
    </Col>
    <Col xs={12} md={6} className="mb-4">
      <motion.div
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        className="feature-card p-3 text-center"
      >
        <FontAwesomeIcon icon={faEdit} size="3x" className="mb-3" />
        <h4>Blog Posts</h4>
        <p>Deliver fresh, SEO-optimized content through regular blog posts. Our content strategy helps attract organic traffic and engage your audience.</p>
      </motion.div>
    </Col>
    <Col xs={12} md={6} className="mb-4">
      <motion.div
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        className="feature-card p-3 text-center"
      >
        <FontAwesomeIcon icon={faChartLine} size="3x" className="mb-3" />
        <h4>Off-Site Content Creation</h4>
        <p>Build backlinks and increase brand authority with off-site content. We create high-quality guest blogs and articles to enhance your SEO efforts.</p>
      </motion.div>
    </Col>
  </Row>
</Container>

</div>


      {/* Contact Form Modal */}
      <ContactForm
        show={showModal}
        handleClose={handleClose}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        selectedPlan={selectedPlan}
      />

      {/* Snackbar for submission message */}
      {showSnackbar && (
        <div className="snackbar">
          Submission successful!
        </div>
      )}
    </div>
  );
};

export default DigitalMarketingPricing;
