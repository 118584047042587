import React, { useState, useEffect, useMemo } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullhorn,
  faDesktop,
  faCode,
  faLock,
  faExpandArrowsAlt,
  faPuzzlePiece,
  faSearch,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import ContactForm from './insurance-contact'; // Import the ContactForm component

const InsurancePricing = () => {
  const initialFormData = useMemo(
    () => ({
      name: '',
      phone: '',
      email: '',
      businessName: '',
      service1: '',
      service2: '',
      service3: '',
      message: '',
      pricingPlanTitle: '', // Added field for the selected pricing plan title
    }),
    []
  );

  const [formData, setFormData] = useState(initialFormData);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [selectedPlan, setSelectedPlan] = useState({
    title: '',
    cost: '',
    features: [],
  }); // State to store the selected plan with default values
  const [showSnackbar, setShowSnackbar] = useState(false); // State for showing snackbar

  const handleResize = () => {};

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    const mainContent = document.getElementById('main-content');

    if (showModal) {
      document.body.style.overflow = 'hidden';
      if (mainContent) mainContent.classList.add('blur-background');
      if (navbar) navbar.classList.add('blur');
    } else {
      document.body.style.overflow = '';
      if (mainContent) mainContent.classList.remove('blur-background');
      if (navbar) navbar.classList.remove('blur');
      setFormData(initialFormData); // Reset form data when modal is closed
    }
  }, [showModal, initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/xrgnrknr', {
        method: 'POST',
        body: new FormData(e.target),
        headers: {
          Accept: 'application/json',
        },
      });
      const data = await response.json();
      if (data.ok) {
        setShowSnackbar(true);
        setTimeout(() => setShowSnackbar(false), 5000);
        setShowModal(false);
      } else {
        alert('Oops! There was a problem with your submission.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending message.');
    }
  };

  const pricingPlans = [
    {
      title: 'Tier 1',
      cost: '$150/month',
      features: [
        'Insurance Website',
        'Fully Hard-Coded Website',
        'Integrated Blog System',
        'Google Profile Optimization',
        'On-Site SEO Optimization',
        'Site Traffic Analytics',
        'Search Rankings Tracking',
        'Reputation Monitoring',
        'Free SSL Certificate',
      ],
      
    },
    {
      title: 'Tier 2',
      cost: '$300/month',
      features: [
        'Includes all Tier 1 Features',
        '2 Short Video/Month',
        '2 Original Blogs/Month',
        '12 Social Posts/Month',
        'Monthly Email Newsletter',
      ],
    },
    {
      title: 'Tier 3',
      cost: '$450/month',
      features: [
        'Includes all Tier 1 Features',
        '2 Short Videos/Month',
        '4 Original Blogs/Month',
        '24 Social Posts/Month',
        'Monthly Email Newsletter',
      ],
    },
  ];

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setFormData({ ...formData, pricingPlanTitle: plan.title });
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <div>
      <div id="main-content">
        <Container
          fluid
          className="jumbotron about-jumbotron text-white text-center d-flex align-items-center justify-content-center py-5"
          style={{ backgroundColor: 'black' }}
        >
          <div>
            <h1>Insurance Website</h1>
            <p>Enhance your business with Custom Branding.</p>
          </div>
        </Container>

        {/* Pricing Cards */}
        <Container className="my-5">
          <h1 className="text-center pricing-table-title">SELECT A PLAN</h1>
          <Row>
            {pricingPlans.map((plan, index) => (
              <Col md={12} lg={4} key={index}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={cardVariants}
                >
                  <Card
                    className={`pricing-card ${
                      index === 1 ? 'pricing-card-highlighted' : ''
                    }`}
                  >
                    <Card.Body>
                      <FontAwesomeIcon
                        icon={
                          index === 0
                            ? faDesktop
                            : index === 1
                            ? faCode
                            : faPuzzlePiece
                        }
                        className="pricing-plan-icon"
                      />
                      <p className="pricing-plan-title">{plan.title}</p>
                      <h3 className="pricing-plan-cost ml-auto">{plan.cost}</h3>
                      <Button
                        onClick={() => handlePlanSelect(plan)}
                        className="pricing-plan-purchase-btn"
                      >
                        Select Plan
                      </Button>
                      <Container className="pricing-plan-features-container">
                        <ul className="pricing-plan-features">
                          {plan.features.map((feature, idx) => (
                            <li key={idx} className="pricing-plan-feature-item">
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="text-primary icon-pricing"
                              />{' '}
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </Container>
                    </Card.Body>
                  </Card>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>

        {/* Features Section */}
        <Container className="mt-5 p-5 bg-light border rounded pricing-con">
          <Row className="justify-content-center">
            <h3 className="what-you text-center mb-4">
              Explore Our Top Features
            </h3>
          </Row>
          <Row className="feature-list">
            {/* Feature 1: Direct Lead Capture */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="3x"
                  className="mb-3"
                />
                <h4>Direct Lead Capture</h4>
                <p>
                  Ensure every quote submission is sent directly to you,
                  eliminating competition from other agents and maximizing your
                  sales potential.
                </p>
              </motion.div>
            </Col>
            {/* Feature 2: Optimized Online Presence */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faSearch} size="3x" className="mb-3" />
                <h4>Optimized Online Presence</h4>
                <p>
                  Boost your visibility online with Google profile
                  optimization, on-site SEO, and targeted online branding
                  strategies.
                </p>
              </motion.div>
            </Col>
            {/* Feature 3: Efficient Quote Forms */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faDesktop} size="3x" className="mb-3" />
                <h4>Efficient Quote Forms</h4>
                <p>
                  Simplify the quoting process with quick, user-friendly forms
                  designed to convert visitors into clients in under a minute.
                </p>
              </motion.div>
            </Col>
            {/* Feature 4: Comprehensive Digital Marketing */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faBullhorn} size="3x" className="mb-3" />
                <h4>Comprehensive Digital Marketing</h4>
                <p>
                  Engage potential clients with strategic digital marketing
                  services, including social media management, blog content, and
                  YouTube videos.
                </p>
              </motion.div>
            </Col>
            {/* Feature 5: Secure and Efficient Quotes */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon icon={faLock} size="3x" className="mb-3" />
                <h4>Secure and Efficient Quotes</h4>
                <p>
                  Ensure every quote request is managed securely and efficiently
                  with direct communication channels and automated
                  confirmations.
                </p>
              </motion.div>
            </Col>
            {/* Additional Features */}
            <Col xs={12} md={6} className="mb-4">
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                className="feature-card p-3 text-center"
              >
                <FontAwesomeIcon
                  icon={faExpandArrowsAlt}
                  size="3x"
                  className="mb-3"
                />
                <h4>Customization Options</h4>
                <p>
                  Our platform is fully customizable to match the unique needs
                  of your insurance business, with personalized features and
                  modular choices.
                </p>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Contact Form Modal */}
      <ContactForm
        show={showModal}
        handleClose={handleClose}
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        selectedPlan={selectedPlan}
      />

      {/* Snackbar for submission message */}
      {showSnackbar && (
        <div className="snackbar">Submission successful!</div>
      )}
    </div>
  );
};

export default InsurancePricing;
