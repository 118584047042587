import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { motion } from 'framer-motion';
import './divine-sections.css';
import img1 from './11 copy.webp';
import img2 from './5 copy.webp';
import img3 from './6 copy.webp';
import img4 from './7 copy.webp';
import img5 from './9 copy.webp';

const fadeUpVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } // Increased duration to 0.8 seconds
};

const DivineSections = () => {
    return (
        <Container className="my-5">
            {/* Adding new sections here */}
            <div className="custom-services-subheader">
                <h3>Divine Messenger</h3><span className="custom-line-icon"></span>
            </div>

            <div className="custom-wwo-section mt-3 mb-5">
                <h2>What Our App Offers</h2>
            </div>

            {/* User-Friendly Interface */}
            <Row className="divine-sections align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img1} alt="User-Friendly Interface" fluid className='divine-img' />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#1 User-Friendly Interface</h2>
                        <p>Our app provides an easy-to-use interface designed to simplify navigation for all users:</p>
                        <ul className="bullet-points-left">
                            <li><strong>Intuitive Design:</strong> A clean and straightforward layout ensures minimal clutter and easy navigation.</li>
                            <li><strong>Clear Labels:</strong> Distinctive icons and labels help users find what they need quickly.</li>
                            <li><strong>Consistent Experience:</strong> Uniform design across screens maintains a seamless experience.</li>
                            <li><strong>Accessibility Options:</strong> Features like text-to-speech and high-contrast modes cater to all users.</li>
                            <li><strong>Quick Access:</strong> Key functions are easy to reach, enhancing overall efficiency and usability.</li>
                        </ul>
                        <p>This focus on simplicity and accessibility ensures a user-friendly experience for everyone.</p>
                    </motion.div>
                </Col>
            </Row>

            {/* Secure Data Management */}
            <Row className="align-items-center mb-custom section-light-gray-1">
                <Col lg={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#2 Secure Data Management</h2>
                        <p>Our app prioritizes data security, ensuring sensitive information is always protected:</p>
                        <ul className="bullet-points-left">
                            <li><strong>Secure Database:</strong> Data is stored in a secure environment with robust encryption.</li>
                            <li><strong>Access Control:</strong> Only authorized personnel can access sensitive information.</li>
                            <li><strong>Regular Audits:</strong> Frequent security checks ensure compliance and data integrity.</li>
                            <li><strong>Data Backup:</strong> Automated backups prevent data loss due to unforeseen circumstances.</li>
                            <li><strong>GDPR Compliance:</strong> Our app meets all relevant data protection regulations.</li>
                        </ul>
                        <p>This comprehensive security approach ensures your data remains confidential and secure at all times.</p>
                    </motion.div>
                </Col>
                <Col lg={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                    <Image src={img2} alt="Secure Data Management" fluid className='divine-img' />
                </Col>
            </Row>

            {/* Paperless Document Handling */}
            <Row className="divine-sections align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img4} alt="Paperless Document Handling" fluid className='divine-img' />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#3 Paperless Document Handling</h2>
                        <p>Our app streamlines document management by going fully digital:</p>
                        <ul className="bullet-points-left">
                            <li><strong>Digital Forms:</strong> All documents are converted into digital formats for easy access.</li>
                            <li><strong>Automated Processes:</strong> Reduces manual entry, minimizing errors and saving time.</li>
                            <li><strong>Easy Retrieval:</strong> Quickly search and retrieve documents without the clutter of paper.</li>
                            <li><strong>Sustainable Approach:</strong> Reduces paper usage, promoting an eco-friendly workplace.</li>
                            <li><strong>Secure Storage:</strong> Digital documents are securely stored and protected from loss.</li>
                        </ul>
                        <p>This transition to a paperless system increases efficiency and supports sustainability.</p>
                    </motion.div>
                </Col>
            </Row>

            {/* Real-Time Workflow */}
            <Row className="align-items-center mb-custom section-light-gray-1">
                <Col lg={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#4 Real-Time Workflow</h2>
                        <p>Our app keeps your team connected with real-time updates and communication:</p>
                        <ul className="bullet-points-left">
                            <li><strong>Instant Updates:</strong> Real-time notifications keep everyone informed of changes.</li>
                            <li><strong>Live Tracking:</strong> Monitor task progress and team activities as they happen.</li>
                            <li><strong>Integrated Messaging:</strong> Communicate directly within the app for seamless coordination.</li>
                            <li><strong>Data Synchronization:</strong> All changes sync instantly, ensuring everyone is on the same page.</li>
                            <li><strong>Improved Collaboration:</strong> Real-time tools enhance teamwork and decision-making.</li>
                        </ul>
                        <p>This feature boosts productivity by ensuring all team members stay aligned and up-to-date.</p>
                    </motion.div>
                </Col>
                <Col lg={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                    <Image src={img3} alt="Real-Time Workflow" fluid className='divine-img' />
                </Col>
            </Row>

            {/* Customization Options */}
            <Row className="divine-sections align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img5} alt="Customization Options" fluid className='divine-img' />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>#5 Customization Options</h2>
                        <p>Our app adapts to your business needs with versatile customization features:</p>
                        <ul className="bullet-points-left">
                            <li><strong>Personalized Interface:</strong> Customize the look and feel to match your brand.</li>
                            <li><strong>Flexible Modules:</strong> Choose the modules you need, tailored to your operations.</li>
                            <li><strong>User Preferences:</strong> Adapt the app to suit different user roles and preferences.</li>
                            <li><strong>Feature Control:</strong> Enable or disable features based on specific requirements.</li>
                            <li><strong>Scalable Design:</strong> Easily scale the app as your business grows and evolves.</li>
                        </ul>
                        <p>With these customization options, our app seamlessly integrates into your workflow.</p>
                    </motion.div>
                </Col>
            </Row>
        </Container>
    );
};

export default DivineSections;
